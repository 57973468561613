import React, { useEffect, useState, useContext } from "react";
import { Link, LoginScreen, Icon } from "@abb/abb-common-ux-react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { RootContext } from "../../contexts/root-context";
import { BASE_URL_MFA, MESSAGES } from "../../strings";
import { Auth } from 'aws-amplify';
import NotificationExample from "../../components/Notification/notification";
import axios from "axios";
import UserSession from "../../components/Authentication/user-session";
import { Input } from "@abb/abb-common-ux-react";
import { Button } from "@abb/abb-common-ux-react";


function ABBLogin() {
  const { authDetails, setauthDetails, isnotify } = useContext(RootContext);
  const navigate = useNavigate();
  const [isError, setIsError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [totpCode, settotpCode] = React.useState();
  const [showVerifyMFA, setShowVerifyMFA] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [getLoginConfig, setgetLoginConfig] = useState({});
  useEffect(() => {
    localStorage.setItem("error", "");
    redirectToLogin();
  }, [isError]);

  useEffect(() => {
  }, [showVerifyMFA])

  const redirectToLogin = () => {
    if (window.location.pathname !== "/") {
      navigate("../");
    }
  };
  const showSuccess = () => {
    navigate("./dashboard");
  };

  const lawText =
    "This is a private system. Do not attempt to login unless you are an authorized user. " +
    "Any authorized or unauthorized access and use may be monitored and can result in " +
    "criminal or civil prosecution under applicable law.";

  const [usersstate, setState] = useState({
    username: "",
    password: "",
    error: false,
  });

  const gotSignUp = () => {
    navigate("../signup");
  };

  const verifyMFACode = async () => {
    setIsError(false);
    setLoading(true);
    if (totpCode) {
      const user = await Auth.signIn(getLoginConfig);
      try {
        let confirmSignInResp = await Auth.confirmSignIn(
          user,
          totpCode,
          "SOFTWARE_TOKEN_MFA"
        );
        console.log("confirmSignInResp", confirmSignInResp)
        // Check if MFA challenge is successfully completed before proceeding
        if (confirmSignInResp.username) {

          setShowVerifyMFA(false)
          setIsError(false);
          showSuccess();
        }
      } catch (e) {
        if (e.name === 'CodeMismatchException') {
          const message = e.message;
          const truncatedMessage = message.split(':')[0];
          localStorage.setItem("error", truncatedMessage);
        } else {
          console.log('An unexpected error occurred:', e.message);
          localStorage.setItem("error", e.message);

        }
        setIsError(true);

      }
    } else {
      setIsError(true);
      localStorage.setItem("error", "Enter Valid Code");
    }
  }

  const cancelForgotPwd = () => {
    setShowVerifyMFA(false)
    setIsError(false);
    setLoading(false);
    navigate("./");
  }



  const handleSubmit = async (username, password) => {
    localStorage.clear();
    setIsError(false);
    try {
      const signInOptions = {
        username,
        password,
      };
      console.log("BASE_URL_MFA:", BASE_URL_MFA);
      console.log("signInOptions", signInOptions);
      setgetLoginConfig(signInOptions);
      const user = await Auth.signIn(signInOptions);

      console.log("user:", user);
      localStorage.setItem("username", username);
      setauthDetails({ ...authDetails, uname: username, password: password, email: user.email, token: user.token });
      const challengeParam = user.challengeName;

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // User needs to set a new password
        navigate("./change-password");
      } else if (challengeParam === "MFA_SETUP") {
        try {
          window.location.href = BASE_URL_MFA;
        } catch (error) {
          console.error("Error during MFA setup:", error);
        }
      } else if (challengeParam === "SOFTWARE_TOKEN_MFA") {
        setLoading(false);
        setShowVerifyMFA(true);
        settotpCode()
        // const totpCode = prompt(
        //   "Enter One--time password code from your authenticator app:"
        // );
        //await verifyMFACode(user, username)
      }
      else {
        localStorage.setItem("error", "");
        setIsError(false);
        setState({ ...usersstate, iserror: false });
        showSuccess();
      }
    } catch (error) {
      console.log('Error signing in:', error);
      setState({ ...usersstate, iserror: true, error: error.toString().split(":")[1] });
      localStorage.setItem("error", error.toString().split(":")[1]);
    }

  };

  const handleIdpLogin = async () => {
    try {
      Auth.federatedSignIn({ provider: "SAML" });
    } catch (err) {
    } finally {
    }
  };

  return (
    <>
      {isnotify === "updatePassword" ? <NotificationExample notitype={isnotify} /> : ""}

      {showVerifyMFA ? (
        <div className="ABB_CommonUX_LoginScreen__root ABB_CommonUX_LoginScreen__light login-container">
          <div className="ABB_CommonUX_LoginScreen__header">
            <div />
            <div className="ABB_CommonUX_LoginScreen__logo" />
          </div>
          <div className="ABB_CommonUX_LoginScreen__contentWrapper">
            <div className="ABB_CommonUX_LoginScreen__content">
              <h1 className="ABB_CommonUX_LoginScreen__productName">Verify your identity</h1>
              <div className="ABB_CommonUX_LoginScreen__form">
                <div className="ABB_CommonUX_LoginScreen__formRow">
                  <div className="ABB_CommonUX_LoginScreen__customContent" style={{ fontSize: "14px", fontWeight: 600 }}>
                    Enter a code from your authenticator app.
                    <div />
                  </div>
                </div>
                <div className="ABB_CommonUX_LoginScreen__formRow" style={{ width: "400px", fontSize: "14px", fontWeight: 500, marginTop: "0px" }}>
                  <div className="ABB_CommonUX_LoginScreen__password">
                    <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                      <Input
                        className="ABB_CommonUX_Input__inputElement"
                        type="normal"
                        name="toptp"
                        id="toptp"
                        value={totpCode}
                        onValueChange={(val) => {
                          setIsSaveEnabled(true)
                          settotpCode(val);
                        }} />
                      {loading && (
                        <p style={{ color: "green" }}>{MESSAGES.SIGN_IN_TRIGGER}</p>
                      )}
                      {isError && (
                        <p style={{ color: "red" }} id="error-msg">
                          <Icon
                            name="abb/warning-circle-1"
                            className="incorrect"
                          ></Icon>
                          {localStorage.getItem("error")}
                        </p>
                      )}
                      <br />
                      <br />
                      <Button
                        text="Login"
                        id="mfa-login"
                        disabled={!isSaveEnabled}
                        shape={"rounded"}
                        sizeClass={"small"}
                        type={"primary-black"}
                        onClick={verifyMFACode}
                      />
                      &nbsp;&nbsp;<Button
                        text="Cancel"
                        id="mfa-cancel"
                        shape={"rounded"}
                        sizeClass={"small"}
                        type={"primary-black"}
                        onClick={cancelForgotPwd}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) :
        (<LoginScreen
          id="abb-login-container"
          productName="Log in"
          className="login-container"

          footerText={lawText}

          onCancel={() => {

          }}
          rememberMeText={false}
          onLogin={(username, password) => {
            if (username && password) {
              handleSubmit(username, password);
            } else {
              setIsError(true);
              localStorage.setItem(
                "error",
                "Enter Valid Username and password..."
              );
            }
          }}
          customContent={(props, state) => {
            return (
              <div id="ad-signup-controls">
                <UserSession />
                {/* Dont have an account <Link onClick={gotSignUP} >SignUp</Link> */}
                {/* <p> Sign in to your CogniEN account </p> */}

                <div className="ad-container">

                  <span> Don't have an account <Link onClick={gotSignUp}>SignUp</Link></span>

                  <span style={{ "marginLeft": "150px" }}> <Link onClick={handleIdpLogin}>Login with ABB Corporate ID</Link></span>


                </div>

                <p style={{ color: "red" }} id="error-msg">
                  {localStorage.getItem("error")}
                </p>
              </div>
            );
          }}
        />)}
    </>
  );
}
export default ABBLogin;
