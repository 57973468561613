import React, { useState, useContext, useEffect } from "react";
import { RootContext } from "../../contexts/root-context";
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';

const ChangePassword = () => {
  const navigate = useNavigate();
  const { authDetails, setisnotify } = useContext(RootContext);
  console.log("all user data", authDetails)
  const [state, setState] = useState({
    password: "",
    newpassword: "",
    comfirmpassword: "",
    error: undefined,

  });

  const [formValues, setFormValues] = useState({
    password: "",
    newpassword: "",
    comfirmpassword: "",
    error: ""
  });

  useEffect(() => {

    if (authDetails.password === undefined) {
      navigate("/");
    }
  }, [])

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value
    })
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
    console.log("changed", e.target.value)
    // setTimeout(() => { console.log("changed", authDetails.password + "||" + formValues.password) }, 2000)
  }

  const [formErrors, setFormErrors] = useState({});



  async function handleSubmit(event) {

    event.preventDefault();


    const errors = {};

    if (!formValues.password) {
      errors.password = 'Current Password is required.';
    }
    if (!formValues.password || authDetails.password !== event.target.password.value) {
      errors.password = 'Enter Valid Password';
    }

    if (!formValues.newpassword) {
      errors.newpassword = 'New password is required.';
    }

    if (formValues.newpassword.length < 6) {
      errors.newpassword = 'Password must be at least 6 characters';
    }

    //|| (/(\d.*[A-Z])|([A-Z].*\d)/).test(formValues.password)
    if (formValues.newpassword !== formValues.comfirmpassword) {
      errors.comfirmpassword = 'Re-entered password is not matched with the New Password field.';
    }
    setFormErrors(errors);
    console.log("error list", errors)

    if (Object.keys(errors).length === 0) {
      // handle form submission
      const user = await Auth.signIn(authDetails.uname, authDetails.password);



      await Auth.completeNewPassword(user, formValues.comfirmpassword);

      await Auth.signOut();

      navigate('../')
      setisnotify("updatePassword");
      console.log('New password set successfully');

    }
  }


  return (
    <div className="ABB_CommonUX_LoginScreen__root ABB_CommonUX_LoginScreen__light login-container">

      <div className="ABB_CommonUX_LoginScreen__header">
        <div />
        <div className="ABB_CommonUX_LoginScreen__logo" />
      </div>
      <div className="ABB_CommonUX_LoginScreen__contentWrapper">

        <div className="ABB_CommonUX_LoginScreen__content">
          <form onSubmit={handleSubmit}  >
            <h1 className="ABB_CommonUX_LoginScreen__productName">Update your password</h1>
            <div className="ABB_CommonUX_LoginScreen__form">
              <div className="ABB_CommonUX_LoginScreen__formRow">
                <div className="ABB_CommonUX_LoginScreen__customContent">
                  <div />
                </div>
              </div>
              <div className="ABB_CommonUX_LoginScreen__formRow">
                <div className="ABB_CommonUX_LoginScreen__password">
                  <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                    <div className="ABB_CommonUX_Input__labelContainer">
                      <span className="ABB_CommonUX_Input__label"><b>Current password</b></span>
                    </div>
                    <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                      <input
                        className="ABB_CommonUX_Input__inputElement"
                        type="password"

                        required
                        name="password"
                        id="password"
                        onKeyUpCapture={handleChange}
                      />
                      <span className="ABB_CommonUX_Input__iconWrapper">
                        <i className="ABB_CommonUX_Icon__root">
                          <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_lock-closed_16" />
                        </i>
                      </span>

                    </div>
                  </div>
                  {formErrors.password && <span className="error-display">{formErrors.password}</span>}

                </div>
                <div>
                  <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                    <div className="ABB_CommonUX_Input__labelContainer">
                      <span className="ABB_CommonUX_Input__label"><b>New password</b></span>
                    </div>
                    <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                      <input
                        className="ABB_CommonUX_Input__inputElement"
                        type="password"

                        defaultValue=""
                        required
                        name="newpassword"
                        id="newpassword"
                        onKeyUpCapture={handleChange}
                      />
                      <span className="ABB_CommonUX_Input__iconWrapper">
                        <i className="ABB_CommonUX_Icon__root">
                          <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_lock-closed_16" />
                        </i>
                      </span>

                    </div>
                  </div>
                  {formErrors.newpassword && <span className="error-display">{formErrors.newpassword}</span>}
                </div>
                <div className="ABB_CommonUX_LoginScreen__password">
                  <div className="ABB_CommonUX_Input__root ABB_CommonUX_Input__light ABB_CommonUX_Input__normal ABB_CommonUX_Input__isValid ABB_CommonUX_Input__isEmpty">
                    <div className="ABB_CommonUX_Input__labelContainer">
                      <span className="ABB_CommonUX_Input__label"><b>Reenter password</b></span>
                    </div>
                    <div className="ABB_CommonUX_Input__inputWrapper ABB_CommonUX_Input__hasFixedSize">
                      <input
                        className="ABB_CommonUX_Input__inputElement"
                        type="password"

                        defaultValue=""
                        required
                        name="comfirmpassword"
                        id="comfirmpassword"
                        onKeyUpCapture={handleChange}
                      />
                      <span className="ABB_CommonUX_Input__iconWrapper">
                        <i className="ABB_CommonUX_Icon__root">
                          <i className="ABB_CommonUX_Icon__icon_abb_16 ABB_CommonUX_Icon-16-style__icon-abb_lock-closed_16" />
                        </i>
                      </span>

                    </div>
                  </div>
                  {formErrors.comfirmpassword && <span className="error-display">{formErrors.comfirmpassword}</span>}

                </div>
              </div>

            </div>
            <br></br>
            <div className="ABB_CommonUX_LoginScreen__bottom">
              <div className="ABB_CommonUX_LoginScreen__buttons ABB_CommonUX_LoginScreen__flexSpring">
                <div className="ABB_CommonUX_LoginScreen__button">
                  <button className="ABB_CommonUX_Button__root ABB_CommonUX_Button__primaryblack ABB_CommonUX_Button__mediumSize ABB_CommonUX_Button__light ABB_CommonUX_Button__rounded" id="update-password">
                    <div>
                      <span className="ABB_CommonUX_Button__text ABB_CommonUX_Button__hasText">
                        Update &nbsp;
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

      </div>


    </div>

  );
};

export default ChangePassword;
