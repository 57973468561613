import "./App.css";
import React, { useState } from "react";
import "@abb/abb-common-ux-react/styles.css";
import { RootProvider } from "./contexts/root-context";
import { getDefaultTimeStamp } from "./util";
import CogniENRoutes from "./routes";
import { Amplify } from "aws-amplify";
import { awsConfig } from "./components/Authentication/aws-config";

function App() {
  console.log("ABB Environment:", process.env);
  Amplify.configure(awsConfig);
  const [SpinnerVal, setSpinner] = React.useState(false);
  let defaultTS = getDefaultTimeStamp();
  const [startTS, setstartTS] = React.useState(defaultTS.startDate);
  const [endTS, setendTS] = React.useState(defaultTS.endDate);
  const [isnotify, setisnotify] = React.useState("");
  const [username, setusername] = React.useState("");
  const [authDetails, setauthDetails] = React.useState([
    { uname: username, password: "", email: "", token: "" },
  ]);
  const [digitalTwinsData, setDigitalTwinsData] = useState();
  const [siteKpis, setSiteKpis] = useState();
  const [powerTrendsData, setPowerTrendsData] = useState();
  const [forecastData, setForecastData] = useState();
  const [energyMixSelectedDuration, setEnergyMixSelectedDuration] =
    useState("day");
  const [powerFlowSelectedOption, setPowerFlowSelectedOption] = useState([
    "Solar Generated Power",
  ]);
  // Trends CO2 Emissions Forecast
  const [
    co2EmissionForecastSelectedDuration,
    setCo2EmissionForecastSelectedDuration,
  ] = useState("Monthly");
  const [selectedDeviceNameOption, setSelectedDeviceNameOption] = useState([
    "HVAC_2N",
  ]);
  const [selectedDeviceMeasureOption, setSelecteDeviceMeasureOption] = useState(
    ["Frequency"]
  );
  // Trends CO2 Emissions
  const [co2EmissionSelectedDuration, setCo2EmissionSelectedDuration] =
    useState("Last 30 days");
  // Trends Energy Mix Forecast
  const [
    energyMixForecastSelectedDuration,
    setEnergyMixForecastSelectedDuration,
  ] = useState("Monthly");
  //Microgid States
  const [isInputSettingClicked, setisInputSettingClicked] = useState(false);
  //Proofer Tool
  const [currentProoferState, setcurrentProoferState] = useState("welcome");
  return (
    <div className="App">
      <div className="main-container">
        <RootProvider
          value={{
            setSpinner,
            SpinnerVal,
            startTS,
            setstartTS,
            endTS,
            setendTS,
            isnotify,
            setisnotify,
            authDetails,
            setauthDetails,
            siteKpis,
            setSiteKpis,
            powerTrendsData,
            setPowerTrendsData,
            energyMixSelectedDuration,
            setEnergyMixSelectedDuration,
            powerFlowSelectedOption,
            setPowerFlowSelectedOption,
            forecastData,
            setForecastData,
            co2EmissionForecastSelectedDuration,
            setCo2EmissionForecastSelectedDuration,
            selectedDeviceNameOption,
            setSelectedDeviceNameOption,
            selectedDeviceMeasureOption,
            setSelecteDeviceMeasureOption,
            digitalTwinsData,
            setDigitalTwinsData,
            co2EmissionSelectedDuration,
            setCo2EmissionSelectedDuration,
            energyMixForecastSelectedDuration,
            setEnergyMixForecastSelectedDuration,
            isInputSettingClicked,
            setisInputSettingClicked,
            currentProoferState,
            setcurrentProoferState
          }}
        >
          <CogniENRoutes />
        </RootProvider>
      </div>
    </div>
  );
}

export default App;
