import React, { useState } from "react";
import {
    Button,
    ToggleSwitch,
    Input
} from "@abb/abb-common-ux-react";
import axios from 'axios';
import "./objective-fun.css";

const ObjectiveFunction = () => {
    // State management for toggles and inputs
    const [monochrome, setMonochrome] = useState(true);

    // Objective states
    const [peakShavingEnabled, setPeakShavingEnabled] = useState(true);
    const [peakShavingWeight, setPeakShavingWeight] = useState("0.25");

    const [energyArbitrageEnabled, setEnergyArbitrageEnabled] = useState(true);
    const [energyArbitrageWeight, setEnergyArbitrageWeight] = useState("0.25");

    const [loadSheddingEnabled, setLoadSheddingEnabled] = useState(true);
    const [loadSheddingWeight, setLoadSheddingWeight] = useState("0.25");

    const [profileSmoothingEnabled, setProfileSmoothingEnabled] = useState(true);
    const [profileSmoothingWeight, setProfileSmoothingWeight] = useState("0.25");

    // Context states
    const [previousHighestPeakKW, setPreviousHighestPeakKW] = useState("0.25");
    const [priceHighestPeakCurrency, setPriceHighestPeakCurrency] = useState("0.25");
    const [endCurrentBillingPeriod, setEndCurrentBillingPeriod] = useState("0.25");

    // Configuration
    const [includeEnergyTracking, setIncludeEnergyTracking] = useState(true);

    const handleSaveUserInfo = () => {
        const payload = {
            objectives: {
                peak_shaving: {
                    realize: peakShavingEnabled,
                    weighting: peakShavingWeight,
                },
                energy_arbitrage: {
                    realize: energyArbitrageEnabled,
                    weighting: energyArbitrageWeight,
                },
                load_shedding: {
                    realize: loadSheddingEnabled,
                    weighting: loadSheddingWeight,
                },
                profile_smoothing: {
                    realize: profileSmoothingEnabled,
                    weighting: profileSmoothingWeight,
                },
            },
            context: {
                previous_highest_peak_kW: previousHighestPeakKW,
                price_highest_peak_currency_per_kW_billing_period: priceHighestPeakCurrency,
                end_of_current_billing_period: endCurrentBillingPeriod,
            },
            configuration: {
                include_energy_tracking: includeEnergyTracking,
            },
        };

        // Make axios POST request
        axios.post('https://q0ipar6sa6.execute-api.ap-south-1.amazonaws.com/data', payload)
            .then(response => {
                console.log('Data saved successfully:', response.data);
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            });
    };

    return (
        <div className="edit-user-container">
            <table id="edit-user-table">
                <tr>
                    <td className="edit-user-col">
                        <b>Peak Shaving</b>
                    </td>
                </tr>
                <tr>
                    <td className="edit-user-col">
                        <span>Enable</span>
                        <ToggleSwitch
                            monochrome={monochrome}
                            label={"True"}
                            value={peakShavingEnabled}
                            onChange={setPeakShavingEnabled}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <Input
                            label="Weighting"
                            sizeClass="small"
                            value={peakShavingWeight}
                            onChange={e => setPeakShavingWeight(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="edit-user-col">
                        <b>Energy Arbitrage</b>
                    </td>
                </tr>
                <tr>
                    <td className="edit-user-col">
                        <span>Enable</span>
                        <ToggleSwitch
                            monochrome={monochrome}
                            label={"True"}
                            value={energyArbitrageEnabled}
                            onChange={setEnergyArbitrageEnabled}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <Input
                            label="Weighting"
                            sizeClass="small"
                            value={energyArbitrageWeight}
                            onChange={e => setEnergyArbitrageWeight(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="edit-user-col">
                        <b>Load Shedding</b>
                    </td>
                </tr>
                <tr>
                    <td className="edit-user-col">
                        <span>Enable</span>
                        <ToggleSwitch
                            monochrome={monochrome}
                            label={"True"}
                            value={loadSheddingEnabled}
                            onChange={setLoadSheddingEnabled}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <Input
                            label="Weighting"
                            sizeClass="small"
                            value={loadSheddingWeight}
                            onChange={e => setLoadSheddingWeight(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="edit-user-col">
                        <b>Profile Smoothing</b>
                    </td>
                </tr>
                <tr>
                    <td className="edit-user-col">
                        <span>Enable</span>
                        <ToggleSwitch
                            monochrome={monochrome}
                            label={"True"}
                            value={profileSmoothingEnabled}
                            onChange={setProfileSmoothingEnabled}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <Input
                            label="Weighting"
                            sizeClass="small"
                            value={profileSmoothingWeight}
                            onChange={e => setProfileSmoothingWeight(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="edit-user-col">
                        <b>Context</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <Input
                            label="previous_highest_peak_kW"
                            sizeClass="small"
                            value={previousHighestPeakKW}
                            onChange={e => setPreviousHighestPeakKW(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <Input
                            label="price_highest_peak_currency_per_kW_billing_period"
                            sizeClass="small"
                            value={priceHighestPeakCurrency}
                            onChange={e => setPriceHighestPeakCurrency(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        <Input
                            label="end_of_current_billing_period"
                            sizeClass="small"
                            value={endCurrentBillingPeriod}
                            onChange={e => setEndCurrentBillingPeriod(e.target.value)}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="edit-user-col">
                        <b>Configuration</b><br />
                        <span>include_energy_tracking</span>
                        <ToggleSwitch
                            monochrome={monochrome}
                            label={"True"}
                            value={includeEnergyTracking}
                            onChange={setIncludeEnergyTracking}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="edit-user-col">
                        <Button
                            text="Save"
                            shape={"pill"}
                            sizeClass={"small"}
                            type={"primary-black"}
                            onClick={handleSaveUserInfo}
                        />
                        <Button
                            text="Cancel"
                            shape={"pill"}
                            sizeClass={"small"} />
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default ObjectiveFunction;
