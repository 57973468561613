import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import "./price-forecast.css";

const PriceForecast = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        const data1 = [
            { name: '17:00', value: 80 },
            { name: '17:30', value: 85 },
            { name: '18:00', value: 90 },
            { name: '18:30', value: 95 },
            { name: '19:00', value: 250 },
            { name: '19:30', value: 250 },
            { name: '20:00', value: 150 },
            { name: '20:30', value: 170 },
            { name: '21:00', value: 120 },
            { name: '21:30', value: 200 },
            { name: '22:00', value: 120 },
            { name: '20:00', value: 200 },
            { name: '20:30', value: 120 },
            { name: '21:00', value: 280 },
            { name: '21:30', value: 80 },
            { name: '22:00', value: 300 },
        ];

        const data2 = [
            { name: '17:00', value: 50 },
            { name: '17:30', value: 55 },
            { name: '18:00', value: 60 },
            { name: '18:30', value: 65 },
            { name: '19:00', value: 100 },
            { name: '19:30', value: 110 },
            { name: '20:00', value: 80 },
            { name: '20:30', value: 85 },
            { name: '21:00', value: 90 },
            { name: '21:30', value: 95 },
            { name: '22:00', value: 70 },
            { name: '20:00', value: 85 },
            { name: '20:30', value: 70 },
            { name: '21:00', value: 90 },
            { name: '21:30', value: 55 },
            { name: '22:00', value: 100 },
        ];

        const chart = echarts.init(chartRef.current);

        const option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['Battery SoC', 'Another Metric'], // Add legend items
                top: '5%', // Position legend at the top
                icon: 'rect'
            },
            xAxis: {
                type: 'category',
                data: data1.map(item => item.name),
                name: 'Time',
                nameLocation: 'middle',
                nameGap: 25,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    padding: -2
                },
            },
            yAxis: [
                {
                    type: 'value',
                    name: 'SoC (KWh)',
                    nameLocation: 'center',
                    nameRotate: 90,
                    nameGap: 20,
                    axisLabel: {
                        padding: -15,
                        verticalAlign: 'top',
                        lineHeight: 10,
                    },
                    interval: 50,
                },
                {
                    type: 'value',
                    name: 'Another Metric',
                    nameLocation: 'center',
                    nameRotate: 90,
                    nameGap: 20,
                    axisLabel: {
                        padding: -15,
                        verticalAlign: 'top',
                        lineHeight: 10,
                    },
                    interval: 20,
                    position: 'right',
                },
            ],
            series: [
                {
                    name: 'Battery SoC',
                    type: 'line',
                    data: data1.map(item => item.value),
                    smooth: true,
                    showSymbol: false,
                    itemStyle: {
                        color: '#e74c3c'
                    },
                },
                {
                    name: 'Another Metric',
                    type: 'line',
                    data: data2.map(item => item.value),
                    smooth: true,
                    showSymbol: false,
                    yAxisIndex: 1, // Bind this series to the second Y-axis
                    itemStyle: {
                        color: '#3498db'
                    },
                },
            ]
        };

        chart.setOption(option);

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div className='price-forecast-chart-container'>
            <div ref={chartRef} className='price-forecast-chart' />
        </div>
    );
};

export default PriceForecast;
