
import React, { useEffect, useState, useContext } from "react";
import { TabItem, TabControl } from "@abb/abb-common-ux-react";
import Load_Cmp from "../../components/Microgrid-Opt-Components/Dashboard-cmpts/Load-profiles/load-profiles";
import "./charts-tab.css"
import MGCard from "../../components/Microgrid-Opt-Components/MGCards/mgcard";
import NetworkDiagram from "../../components/Microgrid-Opt-Components/Network/network";
import Battery_SoC from "../../components/Microgrid-Opt-Components/Dashboard-cmpts/BatterySoC/battery-soc";
import NetworkGreenEnergy from "../../components/Microgrid-Opt-Components/Dashboard-cmpts/Generation/generation";
import NetworkNonGreenEnergy from "../../components/Microgrid-Opt-Components/Dashboard-cmpts/Generation/non-green-energy";
import PriceForecast from "../../components/Microgrid-Opt-Components/Dashboard-cmpts/Grid/price-forecast";
import GridPower from "../../components/Microgrid-Opt-Components/Dashboard-cmpts/Grid/grid-power";
import { RootContext } from "../../contexts/root-context";
import InputParameterSettings from "./input-parameters";

function MicrogridChartsTab() {
    const { isInputSettingClicked } = useContext(RootContext);

    const [activeTab, setActiveTab] = React.useState(0);
    const [type, setType] = React.useState('primary');
    const [monochrome, setMonochrome] = React.useState(false);
    const initialTabs = [
        <TabItem key="1" title="Network">
            <NetworkDiagram />
        </TabItem>,
        <TabItem key="2" title="Load Profiles">
            <div className="row" id="load-profiles-tab-content" >
                <MGCard
                    widgetName="Load 1"
                    widgetUpdateTime="2"
                    component={<Load_Cmp />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Load 1"
                    widgetUpdateTime="2"
                    component={<Load_Cmp />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Load 1"
                    widgetUpdateTime="2"
                    component={<Load_Cmp />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Load 1"
                    widgetUpdateTime="2"
                    component={<Load_Cmp />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Load 1"
                    widgetUpdateTime="2"
                    component={<Load_Cmp />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Load 1"
                    widgetUpdateTime="2"
                    component={<Load_Cmp />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Load 1"
                    widgetUpdateTime="2"
                    component={<Load_Cmp />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Load 1"
                    widgetUpdateTime="2"
                    component={<Load_Cmp />}
                    applyScroll="true"
                ></MGCard>

            </div>
        </TabItem>,
        <TabItem key="3" title="Battery SoC" >
            <div className="row" id="load-profiles-tab-content" >
                <MGCard
                    widgetName="Battery SoC"
                    widgetUpdateTime="2"
                    component={<Battery_SoC />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Battery SoC"
                    widgetUpdateTime="2"
                    component={<Battery_SoC />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Battery SoC"
                    widgetUpdateTime="2"
                    component={<Battery_SoC />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Battery SoC"
                    widgetUpdateTime="2"
                    component={<Battery_SoC />}
                    applyScroll="true"
                ></MGCard>

                <MGCard
                    widgetName="Battery SoC"
                    widgetUpdateTime="2"
                    component={<Battery_SoC />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Battery SoC"
                    widgetUpdateTime="2"
                    component={<Battery_SoC />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Battery SoC"
                    widgetUpdateTime="2"
                    component={<Battery_SoC />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Battery SoC"
                    widgetUpdateTime="2"
                    component={<Battery_SoC />}
                    applyScroll="true"
                ></MGCard>
            </div>
        </TabItem>,
        <TabItem key="4" title="Generation">
            <div className="row" id="load-profiles-tab-content" >
                <MGCard
                    widgetName="Green Energy"
                    widgetUpdateTime="2"
                    component={<NetworkGreenEnergy />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Non Green Energy"
                    widgetUpdateTime="2"
                    component={<NetworkNonGreenEnergy />}
                    applyScroll="true"
                ></MGCard>

            </div>
        </TabItem>,
        <TabItem key="5" title="Grid">
            <div className="row" id="load-profiles-tab-content" >
                <MGCard
                    widgetName="Green Energy"
                    widgetUpdateTime="2"
                    component={<PriceForecast />}
                    applyScroll="true"
                ></MGCard>
                <MGCard
                    widgetName="Non Green Energy"
                    widgetUpdateTime="2"
                    component={<GridPower />}
                    applyScroll="true"
                ></MGCard>
            </div>
        </TabItem>,
    ];

    const [tabs, setTabs] = React.useState(initialTabs);

    return (
        <>


            <TabControl
                type={type}
                id="mg-dashboard-tabs-container"
                style={{ paddingTop: "16px" }}
                activeTab={activeTab}
                onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}

                monochrome={monochrome}
            // style={{ maxWidth: '500px' }}
            >
                {tabs}
            </TabControl>

        </>
    );
}
export default MicrogridChartsTab;

