import React, { useContext, useEffect } from "react";
import Navbar from "../../components/Navbar/navbar";
import MicrogridFilterBar from "../../components/Microgrid-Opt/Filters/filters";
import MetricsContainer from "../../components/Microgrid-Opt/Metrics/metrics-container";
import MicrogridChartsTab from "./charts-tab";
import { RootContext } from "../../contexts/root-context";
import InputParameterSettings from "./input-parameters";
import { useLocation } from "react-router-dom";


function MicrogridOpti() {
    const { isInputSettingClicked } = useContext(RootContext);

    return (
        <>

            {isInputSettingClicked ?
                <>
                    <Navbar />
                    <InputParameterSettings />
                </> :
                <>  <Navbar />
                    <MicrogridFilterBar />
                    <MetricsContainer />
                    <MicrogridChartsTab />
                </>
            }
        </>
    );
}

export default MicrogridOpti;
