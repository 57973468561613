import React from 'react';
import './mgcard.css';


function MGCard(props) {
    const { widgetName, component, widgetUpdateTime, applystyle, applyScroll, isTimePeriod } = props;

    return (
        <div className="mg-card-container">

            <div className='mg-chart-header'>
                <div className='mg-chart-title'>
                    {widgetName}
                </div>
                <div className='mg-card-update-notification'>
                    Last updated  {widgetUpdateTime} mins ago
                </div>
            </div>
            <hr />
            {/* {isTimePeriod ? <PeriodList /> : ""} */}
            {/* {applyScroll ? <div className="mg-card-content" >
                {component}
            </div> : */}
            <div className={"mg-card-content"} >
                {component}
            </div>
            {/* } */}
        </div>
    );
}

export default MGCard;


