import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Dialog, Button, TabControl, TabItem } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../contexts/root-context";
import './popup-output-tabs.css'; // Add your custom styles here
import * as XLSX from 'xlsx';
import UserSession from '../../Authentication/user-session';
import ProoferNavbar from '../proofer-navbar';
import ProoferForm from '../popup-form/proofer-form';
import Navbar from '../../Navbar/navbar';
import AWS from 'aws-sdk';
import ProoferDashboard from '../../../pages/proofer-pages/dashboard/dashboard';

const ProoferTabs = () => {
    const [programmaticDialogIsOpen, setProgrammaticDialogIsOpen] = useState(true);
    const [showDialog, setShowDialog] = useState(false); // State to control dialog visibility

    const { currentProoferState, setcurrentProoferState } = useContext(RootContext);
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0);
    const [type, setType] = useState('primary');
    const [selected, setSelected] = useState('0');

    const prooferFormData = localStorage.getItem('prooferFormData');
    const prooferFormResponse = localStorage.getItem('prooferFormResponse');
    const parsedProoferFormData = prooferFormData ? JSON.parse(prooferFormData) : {};
    const parsedProoferResponse = prooferFormResponse ? JSON.parse(prooferFormResponse) : {};
    const documenId = parsedProoferResponse.document_id;

    const [docSpecSummary, setDocSpecSummary] = useState([]);
    const [proposeProdData, setProposedProdData] = useState({});
    const [docData, setDocData] = useState({
        metadata: {
            oppno: "Loading..",
            filename: "Loading..",
            customerName: "Loading..",
            project: "Loading..",
            state: "Loading..",
            productgroup: "Loading..",
            createdon: "Loading..",
            id: "Loading.."
        }
    });
    const postRequestUrlPrefix = "https://byebj6tdv7.execute-api.ap-south-1.amazonaws.com/dev/proofer/";

    async function getDocData() {
        // const docSpecSummaryResponse = await axios.get(`http://ec2-13-235-65-148.ap-south-1.compute.amazonaws.com:8002/doc/get_spec_summary/${documenId}`);
        // const proposedProdDataResponse = await axios.get(`http://ec2-13-235-65-148.ap-south-1.compute.amazonaws.com:8002/doc/propose_product/${documenId}`);
        // const curDocData = await axios.get(`http://ec2-13-235-65-148.ap-south-1.compute.amazonaws.com:8002/doc/${documenId}`);
        // const headers = {
        //     "Content-Type": "application/json",
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Methods":
        //       "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        //     "Accept-Language": "en-US",
        //   }
          
        const docSpecSummaryResponse = await axios.get(postRequestUrlPrefix + `doc/get_spec_summary/${documenId}`);
        const proposedProdDataResponse = await axios.get(postRequestUrlPrefix + `doc/propose_product/${documenId}`);
        const curDocData = await axios.get(postRequestUrlPrefix + `doc/${documenId}`);

        console.log("Current Doc Data: ", curDocData.data);

        setDocSpecSummary(docSpecSummaryResponse.data.spec_summary);
        setProposedProdData(proposedProdDataResponse.data);
        setDocData(curDocData.data.document);
    }
    const REGION = 'ap-south-1';

    const downloadFile = async () => {
        const s3 = new AWS.S3();

        AWS.config.update({
            accessKeyId: 'ASIAYCEI3CCPW32MQYXR',
            secretAccessKey: 'YZ0H4VZm/v4sYW/i7a9PbgV0GQXQL3sTZ8c0genM',
            sessionToken: 'IQoJb3JpZ2luX2VjEJf//////////wEaCXVzLWVhc3QtMSJGMEQCIH5K5eeHU4xHEDU0j47Hobkn/cCI6tsciz+N1ar7TEcIAiBam1GVvS+ipkoTB0hH8Th/WJ87wFrbRPw6uiCT6FdiViruAgjQ//////////8BEAEaDDU1NDMzNzc2NzU4MyIMUCgBQKfsqZuJvzGjKsICKiOA2H+ZWjGO7XfRxrRI0Cv7hG9AolCbnNkyZ5a+ExRu4Q483R2HgZDOiRtoV82RIaXl7y6BmDaMfwLJp6TFoR2WhJhV78AM86SHlZ3IMm1EmntGy+j2FerUo4PCSXOZoicvZCSOUI9m6hEdsLtfLwuv/7PpDPHTk78u8uez+hIhIFfg9TsiGGJlgF2aVAnXuEhQDad3WfPIT60KWyozEbn1NgU5OE+K1YCFIwLi/NLmtgedd9lxeZTN5KYFv2l755+ssMDkUdvl5oFMcxvJ0PvxPUtanAx2S8a8qOpZV+FKLCLZacVoX4aMfzPdNCtvp+buwG3FNl/WZMLYshmIQRKaPUPHGcC2b1LPZXj9V1tv+Do/nQK4FATccPxG5SOFUazr4Gahayb8Pyoo8LZMJ1Pxh2mNFLWfW6k5k6FsE86RejDcvMm3BjqoAZpCq+tTYCoTOUvwquXn+iy51jiZ/SUP94NlwF9RGDU7b/x5+u3yIg4/4F4pgzVfSrCSA/tVPbx6ts0tyV3CXWCeyln/y0lUf6qsvS/FVxnsyFOQMvB1NKVT63cmXKwQ1XyzavGRlV5K13IQgj2e1huMPMFEbo5iJXG1hRHapB/aJ1q7DivC7cVLCA9hOoh/RyRe2LYp0VVGgpsj8+CUPKlFVgnbf/hGUg==',
            region: REGION,
        });

        const params = {
            Bucket: 'proofer-hackathon-demo-bucket',
            Key: 'documents/output/highlighted1_bbox_Enquiry Document RFQ 1.pdf'
        };

        s3.getSignedUrl('getObject', params, (err, url) => {
            if (err) {
                console.error('Error generating signed URL:', err);
                alert('Error downloading file. Please try again.');
            } else {
                const link = document.createElement('a');
                link.href = url;
                link.download = 'highlighted_document.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });

    };

    useEffect(() => {
        getDocData();
    }, []);

    useEffect(() => {

        if (!docSpecSummary || !proposeProdData || !proposeProdData?.gtp || !proposeProdData?.comparison_deviations) {
            return;
        }

        const GtpTableRows = () => {
            return proposeProdData?.gtp.map((item, index) => (
                <tr key={index}>
                    <td>{item.keyword}</td>
                    <td>{item.query_answer || "NOT PRESENT IN DOCUMENT"}</td>
                    <td>{item.extract_instruction}</td>
                </tr>
            ));
        };

        const DeviationTableRows = () => {
            return proposeProdData?.gtp.map((item, index) => (
                <tr key={index}>
                    <td>{item.query}</td>
                    <td>{item.keyword}</td>
                    <td>{item.query_answer || "NOT PRESENT IN DOCUMENT"}</td>
                    <td>{item.gtp_value}</td>
                    <td>{item.extract_instruction}</td>
                </tr>
            ));
        };

        const comparisonDeviations = Object.entries(proposeProdData?.comparison_deviations)
            .map(([key, value]) => `${key} has query_value ${value.query_value}${value.unit} with actual value ${value.product_value}${value.unit} having deviation of ${value.deviation}`)


        const TableRows = () => {
            return docSpecSummary?.map((item, index) => {
                const pageNumbers = [...new Set(item.doc_metadata.map(meta => meta.page_number))].join(', ');
                const elementClasses = [...new Set(item.doc_metadata.map(meta => meta.element_class))].join(', ');

                return (
                    <tr key={index}>
                        <td>{item.question}</td>
                        <td>{item.answer || "NOT PRESENT IN DOCUMENT"}</td>
                        <td>{pageNumbers}</td>
                        <td>{elementClasses}</td>
                    </tr>
                );
            });
        };

        const initialTabs = [
            <TabItem key="1" title="Spec Summary">
                <div className="table-container">
                    <table className="table" id='spec-summary'>
                        <thead>
                            <tr className="sticky-header">
                                <th>Question</th>
                                <th>Answer</th>
                                <th>Page Numbers</th>
                                <th>Element Classes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRows />
                        </tbody>
                    </table>
                </div>
                <Button
                    className="sticky-btn"
                    text="Download XLS"
                    onClick={() => downloadXL('spec-summary')}
                    shape="pill"
                    type="primary-red"
                />
            </TabItem>
            ,
            <TabItem key="2" title="Deviations" id='deviations'>
                <div className="table-container">
                    <table className="table" id='deviations-table'>
                        <thead>
                            <tr>
                                <th>Query</th>
                                <th>Keyword</th>
                                <th>Query Answer</th>
                                <th>GTP Value</th>
                                <th>Extract Instruction</th>
                            </tr>
                        </thead>
                        <tbody>
                            <DeviationTableRows />
                        </tbody>
                    </table>
                </div>
                <Button
                    text="Download XLS"
                    onClick={() => downloadXL('deviations-table')}
                    shape="pill"
                    type="primary-red"
                />
            </TabItem>,
            <TabItem key="3" title="GTP" id='gtp'>
                <div className="table-container">
                    <table className="table" id='gtp-table'>
                        <thead>
                            <tr>
                                <th>Keyword</th>
                                <th>Query Answer</th>
                                <th>Extract Instruction</th>
                            </tr>
                        </thead>
                        <tbody>
                            <GtpTableRows />
                        </tbody>
                    </table>
                </div>
                <Button
                    text="Download XLS"
                    onClick={() => downloadXL('gtp-table')}
                    shape="pill"
                    type="primary-red"
                />
                <Button
                    text="Annoted PDF"
                    onClick={downloadFile}
                    shape="pill"
                    type="primary-red"
                />

            </TabItem>,
            <TabItem key="4" title="Product Proposal">
                <div>
                    <div className="table-container">
                        <table className="table" id='product-table'>
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Score</th>
                                    <th>comparison Deviations</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ "fontSize": "18px", "fontWeight": "600" }}>{proposeProdData?.product_name}</td>
                                    <td>{proposeProdData?.score}</td>
                                    <td>{comparisonDeviations.map((deviation, index) => (<React.Fragment key={index}> {deviation}<br /></React.Fragment>))}</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                    <Button
                        text="CAD Generator"
                        // onClick={() => downloadXL('product-table')}
                        shape="pill"
                        type="primary-red"
                    />
                </div>
            </TabItem>
        ];

        setTabs(initialTabs);
    }, [docSpecSummary, proposeProdData]);





    const formData = {
        "opportunityNumber": "opp.11.111",
        "projectName": "Proofer",
        "customerName": "John",
        "productGroup": "ABB",
        "file": {},
        "techRequirementFrom": "2",
        "techRequirementTo": "",
        "commRequirementFrom": "3",
        "commRequirementTo": "4"
    }

    const response = parsedProoferFormData;

    const downloadXL = (tableName) => {
        const table = document.getElementById(tableName); // HTML table element
        const workbook = XLSX.utils.table_to_book(table, { sheet: "Sheet1" }); // Create workbook and worksheet
        XLSX.writeFile(workbook, tableName + '.xlsx'); // Write the workbook to file1
    }

    function createProoferForm() {
        setShowDialog(true);
        setcurrentProoferState("create-form");
    }
    function showDashboard() {
        setcurrentProoferState("dashboard");

    }

    const generateCAD = () => {
        // CAD generation logic
    }

    const [tabs, setTabs] = useState([]);

    return (
        <>
            {showDialog && currentProoferState === "create-form" ? <ProoferForm showDialog={showDialog} setShowDialog={setShowDialog} /> : ""}


            <div className="proofer-header-container">
                <div className="col-5 col-m-12 col-s-12">
                    {/* <div className="filter-header">DR Dashboard</div> */}
                </div>
                <div className="col-7 col-m-12 col-s-12">
                    {/* {startTS} To {endTS} */}
                    <div className="filters-group row">
                        <Button
                            text="Generate"
                            onClick={() => createProoferForm()}
                            shape="pill"
                            type="primary-red"
                        />
                        <Button
                            text="Dashboard"
                            onClick={() => showDashboard()}
                            shape="pill"
                            type="primary-red"
                        />
                    </div>
                </div>
            </div>

            <div className='output'>
                <div >
                    <div className="col-4">Opportunity No: {docData?.metadata.oppno}</div>
                    <div className="col-4"></div>
                    <div className="col-4">File Name: {docData?.metadata.filename}</div>
                </div>
                <div >
                    <div className="col-4">Customer Name: {docData?.metadata.customername}</div>
                    <div className="col-4">Project: {docData?.metadata.project}</div>
                    <div className="col-4">Tender State: {docData?.metadata.state}</div>
                </div>
                <div >
                    <div className="col-4">Product Group: {docData?.metadata.productgroup}</div>
                    <div className="col-4">Created On: {docData?.metadata.createdon}</div>
                    <div className="col-4">Opportunity ID: {docData?.metadata.id}</div>
                </div>
                <TabControl
                    className='ABB_CommonUX_TabControl__dark'
                    type={type}
                    allowRemove={true}
                    activeTab={activeTab}
                    onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}
                    style={{ width: '100%', height: "500px" }}
                >
                    {tabs}
                </TabControl>
            </div>
        </>
    );
};

export default ProoferTabs;