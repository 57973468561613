import React, { useState } from "react";
import {
    Button,
    Icon,
} from "@abb/abb-common-ux-react";
import axios from "axios";
import "./network-params.css";

const NetworkParams = () => {
    const [networkFile, setNetworkFile] = useState(null); // To track network file
    const [loadFile, setLoadFile] = useState(null); // To track load file

    // Handle file input change for Network Parameters
    const handleNetworkFileChange = (event) => {
        setNetworkFile(event.target.files[0]);
    };

    // Handle file input change for Load Parameters
    const handleLoadFileChange = (event) => {
        setLoadFile(event.target.files[0]);
    };

    // Function to read and send JSON file using Axios
    const sendFile = (file) => {
        if (!file) {
            console.error("No file selected");
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            try {
                // Parse file content as JSON
                const jsonData = JSON.parse(reader.result);

                // Send POST request with JSON file content
                axios
                    .post("https://q0ipar6sa6.execute-api.ap-south-1.amazonaws.com/data", jsonData, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log("Response:", res.data);
                    })
                    .catch((error) => {
                        console.error("Error sending JSON request:", error);
                    });
            } catch (err) {
                console.error("Error parsing JSON:", err);
            }
        };

        reader.readAsText(file); // Read file content as text
    };

    // Handle Run button for Network Parameters
    const handleRunNetwork = () => {
        sendFile(networkFile);
    };

    // Handle Run button for Load Parameters
    const handleRunLoad = () => {
        sendFile(loadFile);
    };

    return (
        <div className="edit-user-container">
            <table id="edit-user-table">
                <tr>
                    <td className="edit-user-col">
                        <div className="nw-section">
                            <span className="inside-header">Upload Network Parameters</span>
                            <input
                                type="file"
                                label="Weighting"
                                sizeClass="small"
                                accept=".json"
                                className="ip-nw-file"
                                onChange={handleNetworkFileChange} // Track network file
                            />
                            <span style={{ display: "flex" }}>
                                <b>Network.json</b>
                                <span>
                                    <Icon
                                        className="uploaded"
                                        name="abb/check-mark-circle-1"
                                    ></Icon>
                                </span>
                                <span className="nw-file-upload-notification">
                                    Last updated 2 mins ago
                                </span>
                            </span>
                        </div>
                        <div style={{ padding: "8px" }}>
                            <Button
                                text="Run"
                                shape={"pill"}
                                sizeClass={"small"}
                                type={"primary-black"}
                                onClick={handleRunNetwork} // Trigger network file upload
                            />
                            <Button
                                text="Cancel"
                                shape={"pill"}
                                sizeClass={"small"}
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="edit-user-col">
                        <div className="nw-section">
                            <span className="inside-header">Upload Load Parameters</span>
                            <input
                                type="file"
                                label="Weighting"
                                sizeClass="small"
                                accept=".json"
                                className="ip-nw-file"
                                onChange={handleLoadFileChange} // Track load file
                            />
                            <span style={{ display: "flex" }}>
                                <b>Load.json</b>
                                <span>
                                    <Icon
                                        className="uploaded"
                                        name="abb/check-mark-circle-1"
                                    ></Icon>
                                </span>
                                <span className="nw-file-upload-notification">
                                    Last updated 2 mins ago
                                </span>
                            </span>
                        </div>
                        <div style={{ padding: "8px" }}>
                            <Button
                                text="Run"
                                shape={"pill"}
                                sizeClass={"small"}
                                type={"primary-black"}
                                onClick={handleRunLoad} // Trigger load file upload
                            />
                            <Button
                                text="Cancel"
                                shape={"pill"}
                                sizeClass={"small"}
                            />
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default NetworkParams;
