import React from 'react';
import './network.css';
import { Link, Icon } from '@abb/abb-common-ux-react';
import networkImg from '../../../assets/network-diagram.png';


function NetworkDiagram() {

    return (

        <div className="network-container">
            <div class="network-container-title">
                <div className="network-chart-title"><span style={{ "text-transform": "uppercase" }}>NETWORK DIAGRAM</span>
                    <span className='mg-card-update-notification-network'>

                        Last updated  2 mins ago
                    </span>
                </div>

                <Link style={{ float: "right", color: "black" }} href='' onClick={() => { window.open("#/network-preview", "_blank") }} >
                    <Icon
                        className="open-window"
                        name={'abb/' + "open-in-new-window"}
                        sizeClass="medium"
                    />
                </Link>
            </div>
            <hr />
            <img src={networkImg} style={{ "width": "600px", "height": "450px" }} />
        </div>
    )
}
export default NetworkDiagram;
