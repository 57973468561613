import React, { useContext, useEffect } from "react";
import { Icon } from "@abb/abb-common-ux-react";
import "./metrics.css";
import { RootContext } from "../../../contexts/root-context";
function MicroOptMetrics(props) {
    const { startTS, selectedTimeTitle } = useContext(RootContext);
    useEffect(() => {
    }, [startTS])
    //Metrics Names
    const { legends } = props;
    return (
        <div style={{ backgroundColor: 'white' }}>
            {legends.map((legend, index) => {
                switch (legend.metricName) {
                    case "# Total Load Demand":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat < 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat > 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>{legend.metricScore}
                                </span>



                            </div>
                        </div>
                    case "# Total Generation":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat < 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat > 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>
                                    {legend?.metricScore}</span>



                            </div>
                        </div>
                    case "# Total Energy Cost":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat < 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat > 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>{legend.metricScore}</span>



                            </div>
                        </div>
                    case "# Highest Peak":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat > 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat < 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>{legend.metricScore}</span>



                            </div>
                        </div>
                    case "# Total Cost Savings":
                        return <div id="dashboard-metrics" key={index}>

                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat > 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat < 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>{legend.metricScore}</span>



                            </div>
                        </div>
                    case "# Peak costs":
                        return <div id="dashboard-metrics" key={index}>
                            <span className="metric-name">{legend.metricName}</span>
                            <div id="metrics-details">
                                <span className={legend.metricStat < 0 ?
                                    "metric-green metric-score" :
                                    legend.metricStat > 0 ? "metric-red metric-score" :
                                        "metric-nochange metric-score"}>{legend.metricScore}</span>



                            </div>
                        </div>
                }
            })
            }
        </div >
    );

}
export default MicroOptMetrics;


