import React, { useContext, useState } from "react";
import MicroOptMetrics from "./metrics";

function MetricsContainer() {

    const tripMetric = [
        { metricName: "# Total Load Demand", metricScore: 11, metricStat: "kWh" },
    ];

    const distRecordsMetric = [
        { metricName: "# Total Generation", metricScore: 15, metricStat: "kWh" },
    ];
    const ackDRMetric = [
        { metricName: "# Total Energy Cost", metricScore: 754902, metricStat: "$" },
    ];

    const UnackDRMetric = [
        { metricName: "# Highest Peak", metricScore: 1687.02, metricStat: "kWh" },
    ];

    const healthyRelayMetrics = [
        { metricName: "# Total Cost Savings", metricScore: 1431150, metricStat: "$" },
    ];

    const unHealthyRelayMetrics = [
        { metricName: "# Peak costs", metricScore: 1431150, metricStat: "$" },
    ];

    return (
        <div className="filter-row row" style={{ "zIndex": "-1" }}>

            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "16px", "marginLeft": "24px" }} >
                <MicroOptMetrics position={3} legends={tripMetric} />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "16px" }} >
                <MicroOptMetrics position={3} legends={distRecordsMetric} />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "16px" }} >
                <MicroOptMetrics position={3} legends={UnackDRMetric} />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "16px" }} >
                <MicroOptMetrics position={3} legends={ackDRMetric} />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "16px" }} >
                <MicroOptMetrics position={3} legends={healthyRelayMetrics} />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-2" style={{ "padding-left": "16px" }} >
                <MicroOptMetrics position={3} legends={unHealthyRelayMetrics} />
            </div>
        </div>
    )
}

export default MetricsContainer;