export function getDefaultTimeStamp(minutes) {
  minutes = 60;
  if (!isNaN(minutes)) {
    let startDate = new Date();
    let currentDate = `${(startDate.getMonth() + 1 + "").padStart(2, "0")}/${(
      startDate.getDate() + ""
    ).padStart(2, "0")}/${startDate.getFullYear()} ${(
      startDate.getHours() + ""
    ).padStart(2, "0")}:${(startDate.getMinutes() + "").padStart(2, "0")}:${(
      startDate.getSeconds() + ""
    ).padStart(2, "0")}`;
    //One minute ago...
    let dd = startDate.setMinutes(startDate.getMinutes() - minutes);
    //Display the date:
    let date = new Date(dd);
    let day = date.getDate();
    let monthIndex = date.getMonth() + 1;
    let hr = date.getHours();
    let mn = date.getMinutes();
    let sc = date.getSeconds();
    let year = date.getFullYear();
    let minsSubStractDate = `${(monthIndex + "").padStart(2, "0")}/${(
      day + ""
    ).padStart(2, "0")}/${(year + "").padStart(2, "0")} ${(hr + "").padStart(
      2,
      "0"
    )}:${(mn + "").padStart(2, "0")}:${(sc + "").padStart(2, "0")}`;
    // localStorage.setItem("startTime", minsSubStractDate);
    // localStorage.setItem("endTime", currentDate);
    let timestamps = {
      endDate: currentDate,
      startDate: minsSubStractDate,
    };
    return timestamps;
  } else {
    return console.log("Invalid Time Selected");
  }
}
export default function getTimestamps(minutes) {
  if (!isNaN(minutes)) {
    let startDate = new Date();
    let currentDate = `${(startDate.getMonth() + 1 + "").padStart(2, "0")}/${(
      startDate.getDate() + ""
    ).padStart(2, "0")}/${startDate.getFullYear()} ${(
      startDate.getHours() + ""
    ).padStart(2, "0")}:${(startDate.getMinutes() + "").padStart(2, "0")}:${(
      startDate.getSeconds() + ""
    ).padStart(2, "0")}`;
    //One minute ago...
    let dd = startDate.setMinutes(startDate.getMinutes() - minutes);
    //Display the date:
    let date = new Date(dd);
    let day = date.getDate();
    let monthIndex = date.getMonth() + 1;
    let hr = date.getHours();
    let mn = date.getMinutes();
    let sc = date.getSeconds();
    let year = date.getFullYear();
    let minsSubStractDate = `${(monthIndex + "").padStart(2, "0")}/${(
      day + ""
    ).padStart(2, "0")}/${(year + "").padStart(2, "0")} ${(hr + "").padStart(
      2,
      "0"
    )}:${(mn + "").padStart(2, "0")}:${(sc + "").padStart(2, "0")}`;
    // localStorage.setItem("startTime", minsSubStractDate);
    // localStorage.setItem("endTime", currentDate);
    let timestamps = {
      endDate: currentDate,
      startDate: minsSubStractDate,
    };
    return timestamps;
  } else {
    return console.log("Invalid Time Selected");
  }
}

export function getAlarmSummaryLegendsColor(label) {
  switch (label) {
    case "Critical": {
      return "#CC0815";

    }
    case "High": {
      return "#F2AC66";

    }
    case "Medium": {
      return "#FFD800";

    }
    case "Informational": {
      return "#0052FF";

    }
    case "Ok": {
      return "#51A34B";

    }
    default: {
      console.log("Invalid choice");

    }
  }
  return;
}

export function getEventLogByFunctionLegends(label) {
  switch (label) {
    case "Differential Current": {
      return "#FF9F8C";

    }
    case "Overload": {
      return "#B03A2E";

    }

    case "Single Phase Earth Fault": {
      return "#5C0006";

    }
    case "Phase C to ground fault": {
      return "#E12E2E";

    }
    case "Underfrequency": {
      return "#FF99A0";

    }
    case "Phase Unbalance": {
      return "#ED5739";

    }
    case "Voltage Protection": {
      return "#A65503";

    }

    case "Thermal Overload": {
      return "#E74C3C";

    }
    case "Overcurrent Protection": {
      return "#EC7063";

    }

    case "Overvoltage": {
      return "#F1948A";

    }
    case "Directional Earth-Fault": {
      return "#F2D7D5";

    }


    case "Three-phase fault": {
      return "#943126";

    }
    case "Breaker Failure": {
      return "#F0B27A";

    }
    case "Arc Detected": {
      return "#EDBB99";

    }
    case "Phase B to ground fault": {
      return "#D35400";

    }

    case "Phase A to ground fault": {
      return "#D35488";

    }
    default: {
      console.log("Invalid choice");

    }
  }
  return;
}
export function getFaultDurationLegendsColor(label) {
  switch (label) {
    case "2-3 seconds": {
      return "#FF9F8C";

    }
    case "6-10 seconds": {
      return "#5C0006";

    }
    case ">10 seconds": {
      return "#E12E2E";

    }
    case "<2 seconds": {
      return "#E74C3C";

    }
    case "4-5 seconds": {
      return "#ED5739";

    }
    // case "unknown": {
    //   return "#A04000";
    //   break;
    // }

    default: {
      console.log("Invalid choice");

    }
  }
  return;
}
export function getOpsStatusIcon(status) {
  switch (status) {
    case "Closed": {
      return { icon: "abb/information-circle-1", class: "closed" };

    }
    case "Charged": {
      return { icon: "abb/information-circle-1", class: "charged" };

    }
    case "Discharged": {
      return { icon: "abb/warning-circle-1", class: "discharged" };

    }
    case "Sufficient": {
      return { icon: "abb/check-mark-circle-1", class: "charged" };

    }
    case "Insufficient": {
      return { icon: "abb/warning-circle-1", class: "discharged" };

    }
    case "Normal": {
      return { icon: "abb/check-mark-circle-1", class: "charged" };

    }
    case "Tripped": {
      return { icon: "abb/warning-triangle", class: "tripped" };

    }
    case "Failed": {
      return { icon: "abb/error-circle-1", class: "failed" };

    }


    // case "unknown": {
    //   return "#A04000";
    //   break;
    // }

    default: {
      return { icon: "abb/check-mark-circle-1", class: "check-icon" };

    }
  }

}
export function replaceUrlParam(
  url,
  startTimeKey,
  newStartTime,
  endTimeKey,
  newEndTime
) {
  let currentUrl = new URL(url);
  currentUrl.searchParams.set(startTimeKey, newStartTime);
  currentUrl.searchParams.set(endTimeKey, newEndTime);
  const decodedUrl = decodeURIComponent(currentUrl.href);
  // console.log("url params", decodedUrl);
  return decodedUrl;
}
