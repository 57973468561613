import React, { useContext, useEffect } from "react";
import {
  DropdownOption,
  Dropdown,
} from "@abb/abb-common-ux-react";
import getTimestamps from "../../../util";
import { RootContext } from "../../../contexts/root-context";
import "./filters.css";
import { Button } from "@abb/abb-common-ux-react";
import { useNavigate } from "react-router-dom";
function MicrogridFilterBar() {
  const { setstartTS, endTS, setendTS, isInputSettingClicked, setisInputSettingClicked } =
    useContext(RootContext);
  const navigate = useNavigate();

  useEffect(() => {

  }, [isInputSettingClicked])

  const filterOptions = [

    {
      title: "Last 6 hours",
      value: 360,
    },
    {
      title: "Last 12 hours",
      value: 720,
    },
    {
      title: "Last 24 hours",
      value: 1440,
    },
    {
      title: "Last 2 days",
      value: 2880,
    },
  ];
  const [monochrome, setMonochrome] = React.useState(false);
  const [selectedSingle, setSelectedSingle] = React.useState("Last 6 hour");
  const getFilterTime = (filterVal) => {
    setSelectedSingle(String(filterVal[0].label));
    setstartTS(getTimestamps(filterVal[0].value).startDate);
    setendTS(getTimestamps(filterVal[0].value).endDate);
  };

  //Redirect Input Setting page
  const redirectToInputSetting = () => {
    setisInputSettingClicked(true)
    navigate("input-settings");

  };

  return (
    <>
      <div className="filter-row row">
        <div className="col-5 col-m-12 col-s-12">
          <div className="filter-header">EMCore Optimization Summary</div>
        </div>
        <div className="col-7 col-m-12 col-s-12">
          {/* {startTS} To {endTS} */}
          <div className="filters-group row">
            <Button
              icon="abb/settings"
              sizeClass="small"
              text="Input parameter setting"
              onClick={redirectToInputSetting}
              shape="pill"
              style={{ marginTop: "8px" }}
              type="primary-black"
            />
            {/* <-- Time Period --> */}
            <Dropdown
              id="time-period-dd"
              className="filter-dropdown col-s-12"
              label="Time Period"
              placeholder={selectedSingle ? selectedSingle : "Select Option"}
              monochrome={monochrome}
              value={selectedSingle}
              onChange={getFilterTime}
            >
              {filterOptions.map((o, i) => (
                <DropdownOption key={o.title} label={o.title} value={o.value} />
              ))}
            </Dropdown>
            {/* <-- Time Period --> */}

          </div>
        </div>
      </div>
    </>
  );
}
export default MicrogridFilterBar;
