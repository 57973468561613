import React, { useEffect } from "react";
import {
  AppTopNaviDivider,
  AppTopNavi,
  AppTopNaviItem,
  Button,
} from "@abb/abb-common-ux-react";
import avtar from "../../assets/Avatar.png";
import logo from "../../assets/Logo.png";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import "./navbar.css";
function Navbar() {
  const getCurrentMenu = localStorage.getItem("current-page");
  const [monochrome, setMonochrome] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState(
    getCurrentMenu ? getCurrentMenu : "Dashboard"
  );
  const navigate = useNavigate();

  const logout = async () => {
    localStorage.clear();
    localStorage.setItem("isLogin", "false");
    try {
      await Auth.signOut();
      console.log("User logged out");
    } catch (error) {
      console.log("Error signing out:", error);
    }
    navigate("../");
  };
  return (
    <div className="header-container">
      <div className="brand">
        <div>
          <img className="avtar-icon" src={logo} alt="" />
        </div>

        <div className="brand-name">
          {/* <AppTopNaviDivider  /> */}
          <div className="project-name">
            <AppTopNaviDivider />
            <div>
              <div className="title-name">
                CogniEN<sup id="super">TM</sup>
              </div>
              {/* <div>DR Monitoring System</div> */}
            </div>
          </div>
        </div>
      </div>

      <AppTopNavi monochrome={monochrome} allowScroll={true}>
        <AppTopNaviItem
          text="DR Dashboard"
          active={activeItem === "Dashboard"}
          onClick={() => {
            setActiveItem("Dashboard");
            navigate("../dashboard");
            localStorage.setItem("current-page", "dashboard");
          }}
        />

        <AppTopNaviItem
          text="EM Widgets"
          active={activeItem === "em"}
          onClick={() => {
            setActiveItem("em");
            navigate("../em");
            localStorage.setItem("current-page", "em");
          }}
        />

        <AppTopNaviItem
          text="Net-Zero"
          active={activeItem === "nz"}
          onClick={() => {
            setActiveItem("nz");
            navigate("../nz");
            localStorage.setItem("current-page", "nz");
          }}
        />
        {/* Enable this post deploy */}
        {/* <AppTopNaviItem
          text="Microgrid Energy Optimization"
          active={activeItem === "microgrid-opt"}
          onClick={() => {
            setActiveItem("microgrid-opt");
            navigate("../microgrid-opt");
            localStorage.setItem("current-page", "microgrid-opt");
          }}
        /> */}
        <AppTopNaviItem
          text="Proofer Tool"
          active={activeItem === "proofer-tool"}
          onClick={() => {
            setActiveItem("proofer-tool");
            navigate("../proofer-tool");
            localStorage.setItem("current-page", "proofer-tool");
          }}
        />
      </AppTopNavi>

      <Button
        text="Logout"
        onClick={logout}
        shape="pill"
        type="primary-black"
      />
      <div className="profile">
        <img className="avtar-icon" src={avtar} alt="" />
      </div>
    </div>
  );
}

export default Navbar;
