import React, { useEffect, useContext, useState } from "react";
import { Datagrid, Spinner } from "@abb/abb-common-ux-react";
import "./alarm-notifications.css";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import {
  BASE_URL,
  BASE_URL_ALARM_NOTIFICATIONS,
  ERROR_LIST,
} from "../../strings";

function AlarmNotificationsData() {
  const { startTS, endTS } = useContext(RootContext);

  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchAlarmNoti();
  }, [startTS]);

  const fetchAlarmNoti = async () => {
    setispin(true);
    try {
      const response = await axios.get(
        BASE_URL +
          `SMDemo-AlarmNotificationServiceLambda?startDateTime=${startTS}&endDateTime=${endTS}`
      );
      let alarmNotiResp = response.data.data;
      let formatedData = alarmNotiResp.map((v, i) => ({
        fields: {
          bay: v.bay,
          timeStamp: v.timeStamp,
          description: v.description,
        },
      }));
      setData(formatedData);
      setError(false);
      setispin(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };
  const initialDatas = React.useMemo(() => []);

  const initialData = React.useMemo(
    () =>
      initialDatas.map((v, i) => ({
        fields: {
          bay: v.bay,
          timeStamp: v.timeStamp,
          description: v.description,
        },
      })),
    []
  );

  const initialColumns = React.useMemo(
    () => [
      { fieldKey: "bay", title: "BAY" },
      { fieldKey: "timeStamp", title: "TIMESTAMP" },
      { fieldKey: "description", title: "DESCRIPTION", align: "right" },
    ],
    []
  );

  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [data, setData] = React.useState(initialData);
  const [columns, setColumns] = React.useState(initialColumns);

  const [sortOrder, setSortOrder] = React.useState(undefined);
  const [columnOrder, setColumnOrder] = React.useState(undefined);

  return (
    <>
      {isSpin ? (
        <Spinner style={{ margin: "auto" }} color="dark-grey" size="small" />
      ) : (
        <>
          {data.length > 0 ? (
            <Datagrid
              className="table-style-alarm-noti"
              style={{ height: "590px" }}
              data={data}
              columns={data.length > 0 ? columns : ""}
              pageSize={pageSize}
              page={currentPage}
              rowHeight="small"
              enableSorting={true}
              sortOrder={sortOrder}
              onSort={(so) => setSortOrder(so)}
              columnOrder={columnOrder}
            />
          ) : (
            <p className="center-text-error">{ERROR_LIST.NO_NOTIFICATIONS}</p>
          )}
        </>
      )}
    </>
  );
}

export default AlarmNotificationsData;
