import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import "./battery-soc.css";

const Battery_SoC = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        const data = [
            { name: '17:00', value: 80 },
            { name: '17:30', value: 80 },
            { name: '18:00', value: 100 },
            { name: '18:30', value: 120 },
            { name: '19:00', value: 150 },
            { name: '19:30', value: 190 },
            { name: '20:00', value: 200 },
            { name: '20:30', value: 220 },
            { name: '21:00', value: 250 },
            { name: '21:30', value: 280 },
            { name: '22:00', value: 300 },
        ];

        const chart = echarts.init(chartRef.current);

        const option = {
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: data.map(item => item.name),
                name: 'Time',
                nameLocation: 'middle',
                nameGap: 25,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    padding: -2
                },
            },
            yAxis: {
                type: 'value',
                name: 'SoC (KWh)',
                nameLocation: 'center',
                nameRotate: 90,
                nameGap: 20,
                axisLabel: {
                    padding: -15,
                    verticalAlign: 'top',
                    lineHeight: 10,
                },
                interval: 50, // Set interval between y-axis values
            },
            legend: {
                data: ['Battery Soc'], // Add legend items
                top: '5%', // Position legend at the top
                icon: 'rect'
            },
            series: [
                {
                    name: 'Battery Soc', // Name for the first legend item
                    type: 'line',
                    data: data.map(item => item.value),
                    smooth: true,
                    showSymbol: false,
                    itemStyle: {
                        color: '#334ff1' // Set line color for Active Power
                    },
                },

            ]
        };

        chart.setOption(option);

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div className='battery-soc-chart-container'>
            <div ref={chartRef} className='battery-soc-chart' />
        </div>
    );
};

export default Battery_SoC;
