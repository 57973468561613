import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import "./grid-power.css"; // Assuming you have some basic styles for your chart container

const RainfallVsEvaporation = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        const rainfallData = [
            { name: 'Jan', value: 2.0 },
            { name: 'Feb', value: 4.9 },
            { name: 'Mar', value: 7.0 },
            { name: 'Apr', value: 23.2 },
            { name: 'May', value: 25.6 },
            { name: 'Jun', value: 76.7 },
            { name: 'Jul', value: -135.6 },
            { name: 'Aug', value: 162.2 },
            { name: 'Sep', value: 32.6 },
            { name: 'Oct', value: 20.0 },
            { name: 'Nov', value: 6.4 },
            { name: 'Dec', value: 3.3 },
        ];

        const evaporationData = [
            { name: 'Jan', value: 2.6 },
            { name: 'Feb', value: 5.9 },
            { name: 'Mar', value: 9.0 },
            { name: 'Apr', value: 26.4 },
            { name: 'May', value: 28.7 },
            { name: 'Jun', value: 70.7 },
            { name: 'Jul', value: 175.6 },
            { name: 'Aug', value: 182.2 },
            { name: 'Sep', value: 48.7 },
            { name: 'Oct', value: 18.8 },
            { name: 'Nov', value: 6.0 },
            { name: 'Dec', value: 2.3 },
        ];

        const chart = echarts.init(chartRef.current);

        const option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['Rainfall', 'Evaporation'],
                top: '5%',
                icon: 'rect'
            },
            grid: {
                top: 60,
                bottom: 60,
                left: 50,
                right: 50,
            },
            xAxis: {
                type: 'category',
                data: rainfallData.map(item => item.name),
                name: 'Month',
                nameLocation: 'middle',
                nameGap: 25,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    padding: -2
                },
            },
            yAxis: [
                {
                    type: 'value',
                    name: 'Value (mm)',
                    nameLocation: 'center',
                    nameRotate: 90,
                    nameGap: 20,
                    axisLabel: {
                        padding: -15,
                        verticalAlign: 'top',
                        lineHeight: 10,
                    },
                    interval: 50,
                }
            ],
            series: [
                {
                    name: 'Rainfall',
                    type: 'line',
                    data: rainfallData.map(item => item.value),
                    smooth: true,
                    showSymbol: false,
                    itemStyle: {
                        color: '#3498db'
                    },
                },
                {
                    name: 'Evaporation',
                    type: 'line',
                    data: evaporationData.map(item => item.value),
                    smooth: true,
                    showSymbol: false,
                    itemStyle: {
                        color: '#e74c3c'
                    },
                },
            ]
        };

        chart.setOption(option);

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div className='chart-container'>
            <div ref={chartRef} className='chart' />
        </div>
    );
};

export default RainfallVsEvaporation;
