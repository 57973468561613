import React, { useEffect, useContext, useState } from "react";
import {
  Link,
  Button,
  Datagrid,
  Dialog,
  Spinner,
  Icon,
  Input,
  Dropdown,
  DropdownOption,
} from "@abb/abb-common-ux-react";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import {
  BASE_URL,
  BASE_URL_DISTURBANCE_GENERATE_URL,
  ERROR_LIST,
  BASE_URL_CAES,
  S3_BUCKET_DR_CAES
} from "../../strings";
import "./disturbance-measurements.css";
function DisturbanceMeasurementData() {
  const {
    SpinnerVal,
    setSpinner,
    startTS,
    endTS,
    isnotify,
    setisnotify,
    authDetails,
    setauthDetails,
  } = useContext(RootContext);

  const [disturbanceRecordsResponse, setdisturbanceRecordsResponse] = useState(
    []
  );
  const [isSpin, setispin] = useState(true);
  const [StreamUrl, setStreamUrl] = React.useState();
  const [error, setError] = useState(false);
  const [startdate, setstartDate] = React.useState();
  const [exactCauses, setexactCauses] = React.useState([
    { exactcause1: "", exactcause2: "" },
  ]);
  const [probableCauses, setprobableCauses] = useState([
    { probablecause1: "", probablecause2: "", probablecause3: "" }
  ]);
  const [availableMLFault, setavailableMLFault] = useState("others");
  const initialDatas = React.useMemo(() => []);
  const initialColumns = React.useMemo(() => [
    { fieldKey: "relayName", title: "DR File" },
    { fieldKey: "timeStamp", title: "FAULT TIME" },
    { fieldKey: "action", title: "ACTION" },
  ]);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState();
  const [data, setData] = React.useState(undefined);
  const [columns, setColumns] = React.useState(initialColumns);

  const [sortOrder, setSortOrder] = React.useState(undefined);
  const [columnOrder, setColumnOrder] = React.useState(undefined);
  const [isDialogOpen, setisDialogOpen] = useState(false);
  const [currentStep, setcurrentStep] = useState("ANALYSIS_COMPLETE");
  const [isOthers, setisOthers] = useState(true);
  //const postRequestUrlPrefix = 'https://76eemongy5.execute-api.ap-south-1.amazonaws.com/DevDemo/';
  const postRequestUrlPrefix = BASE_URL_CAES;

  function getAppStremUrl(url, fileName) {
    const uName = localStorage.getItem("username")
    let appsStreamURL =
      BASE_URL +
      `SMDemo-DisturbanceRecordsServiceLambda?isList=false&startDateTime=${startTS}&endDateTime=${endTS}&userId=${uName}&fileName=${url}`;
    axios
      .get(appsStreamURL)
      .then((response) => {
        //   setTimeout(() => { window.open(response.data.url, "_blank"); setisnotify(false); }, 5000)
        setisnotify("");
        window.open(response.data.url, "_blank");
        setispin(false);
      })
      .catch(function (error) {
        setisnotify("error");

        console.log("Appstream url error", error.message);
      })
      .finally(() => { });
  }

  //const distRecords = useMemo(() => distRecordsData(distRecords), [distRecords]);

  if (authDetails.uname === undefined) {
    setauthDetails({
      ...authDetails,
      uname: localStorage.getItem("logged_in_user"),
    });
  }
  const [responseData, setResponseData] = useState(null);
  const [utilData, setUtilData] = useState({
    fault_name: " ",
    should_patch: false,
  });
  let probable_cause_val = "";
  // added by Yash - start
  const [requestInferData, setRequestInferData] = useState(null);
  const [exactCausesGiven, setExactCausesGiven] = useState(null);
  const drFilesS3Uri = S3_BUCKET_DR_CAES;

  // add the useEffect hook which runs on requestInferData change, it calls the getServe function
  useEffect(() => {
    if (utilData.should_patch) {
      patchUtil(utilData);
    }
  }, [utilData]);

  // added by Yash - end


  useEffect(() => {
    getApiData();
    // getServe();
  }, [startTS, StreamUrl]);

  function handleDRfileLink(path, fileName) {
    setisnotify("true");

    getAppStremUrl(path, fileName);
  }

  const clearData = () => {
    setcurrentStep("ANALYSIS_COMPLETE");
    setisDialogOpen(false);
    setexactCauses([{ exactcause1: "", exactcause2: "" }]);
    setprobableCauses([{ probablecause1: "", probablecause2: "", probablecause3: "" }]);
  };
  const patchUtil = (utilData) => {
    //debugger;
    const postData = {
      "uri": utilData.uri,
      "fault_name": utilData.fault_name,
      "is_new_fault": utilData.is_new_fault,
      "probable_causes": utilData.probable_causes,
      "exact_causes": utilData.exact_causes
    }
    const postUrl = postRequestUrlPrefix + "util";
    const headers = {
      'Content-Type': 'application/json',
    };
    // Send the POST request using Axios
    // set cuyrrentStep to ANALYSIS_WAIT
    // setcurrentStep("ANALYSIS_WAIT");
    axios
      .post(postUrl, JSON.stringify(postData), {
        headers: headers,
      })
      .then((response) => {
        // console.log("DDD data", response);
        // Handle the response data here
        //debugger;
        console.log("Response data for util:", response.data);
        // debugger;
        // setResponseData(response.data[0]);
        // setcurrentStep("ANALYSIS_COMPLETE");
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  };
  const getServe = async (requestData) => {
    const postData = {
      'uris': requestData.uris
    };
    // debugger;
    // Define the URL for your POST request
    const postUrl = postRequestUrlPrefix + "infer";
    const headers = {
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*', // Required for CORS support to work
      // 'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
      // 'Accept': '*/*',
      // 'Connection': 'keep-alive',

      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      // "Access-Control-Allow-Headers":
      // "Content-Type, Authorization, X-Amz-Date, X-Api-Key, X-Amz-Security-Token",
      // "Access-Control-Allow-Credentials": "true",
      // "Access-Control-Max-Age": "*",
      // "Access-Control-Expose-Headers": "Content-Type",
    };
    // Send the POST request using Axios
    // set cuyrrentStep to ANALYSIS_WAIT
    setcurrentStep("ANALYSIS_WAIT");
    axios
      .post(postUrl, JSON.stringify(postData), {
        headers: headers,
      })
      .then((response) => {
        console.log("DDD data", response);
        // Handle the response data here
        console.log("Response data for infer:", response.data);
        // debugger;
        setResponseData(response.data[0]);
        //debugger;
        setcurrentStep("ANALYSIS_COMPLETE");
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
        setcurrentStep("ANALYSIS_FAILED");
      });
  };
  const getExactCausesGiven = async (requestData) => {
    const postData = {
      'uris': requestData.uris,
      'should_calculate_exact_given': "True",
      'fault_name_arr_given': requestData.fault_name_arr
    };

    // debugger;
    // Define the URL for your POST request
    const postUrl = postRequestUrlPrefix + "infer";
    const headers = {
      'Content-Type': 'application/json',

    };
    // Send the POST request using Axios
    // set cuyrrentStep to ANALYSIS_WAIT
    setcurrentStep("NO_STEP_SHOW_EXACT_CAUSE_WAIT");
    axios
      .post(postUrl, JSON.stringify(postData), {
        headers: headers,
      })
      .then((response) => {
        console.log("DDD data", response);
        // Handle the response data here
        console.log("Response data for infer:", response.data);
        // debugger;
        setExactCausesGiven(response.data[0]);
        //debugger;
        setcurrentStep("NO_STEP_SHOW_EXACT_CAUSE");
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  };
  const getApiData = async () => {
    // debugger;
    setispin(true);
    try {
      console.log("uname" + authDetails.uname);
      console.log(authDetails);
      const response = await axios.get(
        BASE_URL +
        `SMDemo-DisturbanceRecordsServiceLambda?isList=true&startDateTime=${startTS}&endDateTime=${endTS}&username=${authDetails.uname}`
      );
      let distrRecordsResp = response.data.data;
      let formatedData = distrRecordsResp.map((v, i) => ({
        fields: {
          relayName: (
            <Link
              href=""
              onClick={() => handleDRfileLink(v.path, v.fileName)}
              target="_blank"
            >
              {v.fileName}{" "}
              <Icon
                className="open-window"
                name={"abb/" + "open-in-new-window"}
                size="small"
              />
            </Link>
          ),
          timeStamp: v.timeStamp,
          action: (
            <Button
              id="ack-button"
              icon="abb/settings"
              sizeClass={"small"}
              type="ghost"
              text="CAES ML"
              onClick={() => {
                // debugger;
                // remove .dat from the file name and add .cfg and store it in configFileName
                setRequestInferData({
                  fileName: v.fileName,
                  relayName: v.relayName,
                })
                const configFileName = v.fileName.replace(".dat", ".cfg");
                const fileUri = drFilesS3Uri + configFileName;
                openDialogAndFetchMLOPData(fileUri);
                console.log();
              }}
              shape={"pill"}
            />
          ),
        },
      }));
      setData(formatedData);
      setError(false);
      setispin(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };

  // write a function which returns <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} </span>
  const getAnalysisInProgress = () => {
    return (
      <span>
        Analysis in progress for the DR file {requestInferData.fileName} in relay {requestInferData.relayName}
      </span>
    );
  };

  const getAnalysisComplete = () => {
    return <>
      {/* <span>
        <Icon
          className="charged"
          name="abb/check-mark-circle-2"
          sizeClass="medium"
        />{" "}
        <span style={{ "vertical-align": "super" }}>
          Analysis Complete!
        </span>
      </span> */}
      <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} </span>
      <span>Trip type: {responseData.trip_check}</span>
      <span className="dr-reason">{responseData.predicted_fault_value}</span>

    </>
  };
  const getAnalysisFailed = (selections, requestInferData) => {
    const selectionArray = Array.isArray(selections) ? selections : Object.values(selections);
  
    const [channels, parameters] = selectionArray;
  
    const selectedItems = [];
    // Iterate over 9 channels and parameters
    for (let index = 0; index < 9; index++) {
      const channelArray = channels?.[`channel${index + 1}`];
      const parameterArray = parameters?.[`parameter${index + 1}`];
  
      // Check if both channel and parameter exist and are non-empty
      if (channelArray && channelArray.length > 0 && parameterArray && parameterArray.length > 0) {
        const channel = channelArray[0];
        const parameter = parameterArray[0];
  
        selectedItems.push(
          <React.Fragment key={index}>
            <span>
              {channel.value}: {parameter.value}
            </span>
            <br />
          </React.Fragment>
        );
      }
    }
  
    return (
      <>
        <p>Great, thank you for your awesome feedback.<br />
        I have learnt the analog channel values shown below for this disturbance record.</p>
        <p>Analog Channels for the DR file: <strong>{requestInferData.fileName}</strong></p>
        <p><strong>Channels (9)</strong></p>
        {selectedItems.length > 0 ? selectedItems : <p>No valid channel-parameter pairs available.</p>}
      </>
    );
  };
  

  const getFaultIdentified = () => {
    return (
      <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} </span>
    );
  };

  const getProbableCause = () => {
    return (
      <>
        <span>
          {" "}
          Here are some of the probable causes of this type of fault.{" "}
        </span>
        <ol style={{ "text-align": "left" }}>
          {/* run map on responseData.probable_causes and return li */}
          {responseData.probable_causes.filter(cause => cause !== "").map((cause, index) => (
            <li key={index}>{cause}</li>
          ))}

        </ol>

        <span></span>
      </>
    );
  };

  const getExactCause = () => {
    // debugger;
    return (
      <>
        <span> Here are the exact causes of this type of fault. </span>
        <ol style={{ "text-align": "left" }}>
          {/* run map on responseData.exact_causes and return li */}
          {responseData.exact_causes.map((cause, index) => (
            <li key={index}>{cause}</li>
          ))}

        </ol>
      </>
    );
  };



  // const [isExactCauseOpen, setisExactCauseOpen] = useState(false);
  // const [isAnalysisFinish, setisAnalysisFinish] = useState(false);

  const openDialogAndFetchMLOPData = (fileUri) => {
    // do an axios post call here to https://glfycdjjk7.execute-api.ap-south-1.amazonaws.com/Demo/infer
    // with the fileUri as the request body 
    // and then set the response to the state variable requestInferData
    // debugger;
    getServe({ uris: [fileUri] }).then(() => {
      setisDialogOpen(true);
    });

  };

const channels = ["IL1","IL2","IL3","I0","U0","U1","U2","U3","U1B"];
const channelParameters = ["Phase Current 1", "Phase Current 2", "Phase Current 3", "Neutral Current", "Neutral Voltage", "Phase Voltage 1", "Phase Voltage 2", "Phase Voltage 3"];
const [disabled, setDisabled] = React.useState(false);
const [useCustomTemplates, setUseCustomTemplates] = React.useState(false);
const [monochrome, setMonochrome] = React.useState(false);
const [initialOpen, setInitialOpen] = React.useState('none');
const [selections, setSelections] = React.useState({
  selectedChannels: { channel1: null, channel2: null, channel3: null, channel4: null, channel5: null, channel6: null, channel7: null, channel8: null, channel9: null },
  selectedParameters: { parameter1: null, parameter2: null, parameter3: null, parameter4: null, parameter5: null, parameter6: null, parameter7: null, parameter8: null, parameter9: null },
});
const [showContent, setShowContent] = React.useState(true);
const [options, setOptions] = React.useState(channels);
const [channelParametersOption, setChannelParametersOption] = React.useState(channelParameters);
const [analysisFailed, setAnalysisFailed] = useState(false);
const [showNextPage, setShowNextPage] = useState(false);

const commonFlags = {
  disabled,
  clearOnEscape: true,
  sizeClass: 'small',
};

const handleChannelChange = (value, channel) => {
  setSelections((prevSelections) => ({
    ...prevSelections,
    selectedChannels: {
      ...prevSelections.selectedChannels,
      [channel]: value,
    },
  }));
};


const handleParameterChange = (value, parameter) => {
  setSelections((prevSelections) => ({
    ...prevSelections,
    selectedParameters: {
      ...prevSelections.selectedParameters,
      [parameter]: value,
    },
  }));
};

useEffect(() => {
  if (currentStep === "ANALYSIS_FAILED") {
    setAnalysisFailed(true);
  }
}, [currentStep]);

const resetSelections = () => {
  setSelections({
    selectedChannels: {
      channel1: null,
      channel2: null,
      channel3: null,
      channel4: null,
      channel5: null,
      channel6: null,
      channel7: null,
      channel8: null,
      channel9: null,
    },
    selectedParameters: {
      parameter1: null,
      parameter2: null,
      parameter3: null,
      parameter4: null,
      parameter5: null,
      parameter6: null,
      parameter7: null,
      parameter8: null,
      parameter9: null,
    },
  });
};


  return (
    <>
      {isDialogOpen && (
        <Dialog
          className="dialog-container"
          showCloseButton={true}
          closeOnEscape={true}
          closeOnLostFocus={true}
          hideBackground={true}
          onClose={() => {
            clearData();
          }}
          title="CAES ML Analysis"
          standardButtonsOnBottom={
            currentStep === "ANALYSIS_FAILED" ? [
              { text: 'Cancel', type: 'discreet-blue', handler: () => { setisDialogOpen(false); resetSelections(); } },
              { text: 'Next', type: 'primary-blue', handler: () => {
                  setcurrentStep("SHOW_ANALYSIS_FAILED_NEXT_PAGE");
                  setAnalysisFailed(false);
                }
              }
            ] : currentStep === "SHOW_ANALYSIS_FAILED_NEXT_PAGE" ? [
              { text: 'Cancel', type: 'discreet-blue', handler: () => { setisDialogOpen(false); resetSelections(); } }
            ] : []
            // [
              // { text: 'Cancel', type: 'discreet-blue', handler: () => {console.log("No")} },
              // { text: 'Yes', type: 'primary-blue', handler: () => setisAnalysisComplete(true) },
              // { text: 'No', type: 'discreet-blue', handler: (dlg) => setisDialogOpen(false) },
              //(dlg) => setConfirmDRAck(false) && setDrAckStatus("Acknowledged") },
            // ]
          }
        >
          <div className="dialog-content">
            {currentStep === "ANALYSIS_WAIT" && (
              <div className="dialog-section-1">
                <span>
                  Analysis in progress for the DR file {requestInferData.fileName} in relay {requestInferData.relayName}
                </span>
                <span> Please wait while we analyze the fault</span>
                <Spinner style={{ margin: "auto" }} color="dark-grey" size="small" />
              </div>
            )
            }


            {currentStep === "SHOW_ANALYSIS_FAILED_NEXT_PAGE" && (
              <div className="analysis-failed-show-next-page">
                {getAnalysisFailed(selections, requestInferData)}
                </div>
              )}

            {currentStep === "ANALYSIS_FAILED"  && (
              <div className="analysis-failed-container">
                <span>Unable to Perform the Analysis. Please help us learn by mapping the analog channels below</span>
                <div className='analysis-failed-dropdowns'>
                <Dropdown
                label="Channel 1"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedChannels.channel1}
                onChange={(val) => handleChannelChange(val, 'channel1')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                 {options.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel Parameter"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedParameters.parameter1}
                onChange={(val) => handleParameterChange(val, 'parameter1')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                  {channelParametersOption.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel 2"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedChannels.channel2}
                onChange={(val) => handleChannelChange(val, 'channel2')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                  {options.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel Parameter"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedParameters.parameter2}
                onChange={(val) => handleParameterChange(val, 'parameter2')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                  {channelParametersOption.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel 3"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedChannels.channel3}
                onChange={(val) => handleChannelChange(val, 'channel3')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                  {options.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel Parameter"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedParameters.parameter3}
                onChange={(val) => handleParameterChange(val, 'parameter3')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                  {channelParametersOption.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel 4"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedChannels.channel4}
                onChange={(val) => handleChannelChange(val, 'channel4')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                 {options.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel Parameter"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedParameters.parameter4}
                onChange={(val) => handleParameterChange(val, 'parameter4')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                  {channelParametersOption.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel 5"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedChannels.channel5}
                onChange={(val) => handleChannelChange(val, 'channel5')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                 {options.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel Parameter"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedParameters.parameter5}
                onChange={(val) => handleParameterChange(val, 'parameter5')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                  {channelParametersOption.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel 6"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedChannels.channel6}
                onChange={(val) => handleChannelChange(val, 'channel6')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                 {options.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel Parameter"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedParameters.parameter6}
                onChange={(val) => handleParameterChange(val, 'parameter6')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                  {channelParametersOption.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel 7"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedChannels.channel7}
                onChange={(val) => handleChannelChange(val, 'channel7')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                 {options.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel Parameter"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedParameters.parameter7}
                onChange={(val) => handleParameterChange(val, 'parameter7')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                  {channelParametersOption.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel 8"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedChannels.channel8}
                onChange={(val) => handleChannelChange(val, 'channel8')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                 {options.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel Parameter"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedParameters.parameter8}
                onChange={(val) => handleParameterChange(val, 'parameter8')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                  {channelParametersOption.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel 9"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedChannels.channel9}
                onChange={(val) => handleChannelChange(val, 'channel9')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                 {options.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                <Dropdown
                label="Channel Parameter"
                placeholder="Pick one"
                monochrome={monochrome}
                value={selections.selectedParameters.parameter9}
                onChange={(val) => handleParameterChange(val, 'parameter9')}
                initialOpen={initialOpen === 'single'}
                {...commonFlags}
                >
                  {channelParametersOption.map((o) => (
                    <DropdownOption
                      key={o}
                      label={o}
                      value={o}
                      disabled={o.disabled}
                    />
                  ))}
                </Dropdown>
                </div>
              </div>
            )}

            {currentStep === "ANALYSIS_COMPLETE" && (
              <div className="dialog-section-1">
                {getAnalysisComplete()}
                <span> Does this fault look correct?</span>

                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Yes"
                    onClick={() => {
                      setcurrentStep("SHOW_PROBABLE_CAUSE");
                    }}
                    shape={"pill"}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="No"
                    onClick={() => {
                      // debugger;
                      setcurrentStep("NO_CASE_ANALYSIS");
                    }}
                    shape={"pill"}
                  />
                </span>
              </div>
            )}

            {currentStep === "NO_CASE_ANALYSIS" && (
              <div className="dialog-section-2">
                {getAnalysisComplete()}
                <span>We are sorry, you find the answer not correct</span>
                <br />
                <span>
                  Would you want to help us learn from the below options
                </span>
                <span className="button-lists">
                  {
                    Object.entries(responseData.fault_matches).map((fault) => (
                      <Button
                        id={`ack-button-${fault[0]}`}
                        sizeClass={"small"}
                        type="ghost"
                        onClick={() => {
                          // probable_cause_val = fault;
                          // setavailableMLFault(fault);
                          setisOthers(false);
                          setUtilData({
                            uri: drFilesS3Uri + requestInferData.fileName.replace(".dat", ".cfg"),
                            fault_name: `${fault[1]}`,
                            is_new_fault: false,
                            probable_causes: responseData.fault_causes[`${fault[1]}`],
                            exact_causes: [],
                            should_patch: false

                          });
                          // setcurrentStep("WRONG_ML_FAULT");
                        }}
                        text={`${fault[1]}`}
                        shape={"pill"}
                      />
                    ))}
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    onClick={() => {
                      setisOthers(true);
                      setUtilData({
                        uri: drFilesS3Uri + requestInferData.fileName.replace(".dat", ".cfg"),
                        fault_name: ' ',
                        is_new_fault: true,
                        probable_causes: [],
                        exact_causes: [],
                        should_patch: false

                      });
                    }}
                    type="ghost"
                    text="Others"
                    shape={"pill"}
                  />
                </span>
                {/* show a shapn showing chosen fault name using utilData.fault_name */}
                <span>
                  {utilData.fault_name === " " ? "New fault chosen" : "The chosen fault name is" + utilData.fault_name}
                </span>
                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Cancel"
                    onClick={() => {
                      setcurrentStep("ANALYSIS_COMPLETE");
                    }}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Next"
                    onClick={() => {
                      isOthers
                        ? setcurrentStep("WRONG_ML_FAULT")
                        : setcurrentStep("NAME_WRONG_ML_FAULT");
                      console.log("isOthers:", isOthers);
                    }}
                  />
                </span>
              </div>
            )}
            {currentStep === "NAME_WRONG_ML_FAULT" && (
              <div className="dialog-section-2">
                <span>
                  <Icon
                    className="charged"
                    name="abb/check-mark-circle-2"
                    sizeClass="medium"
                  />{" "}
                  <span style={{ "vertical-align": "super" }}>
                    Analysis Complete!
                  </span>
                </span>
                <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                <span className="dr-reason">{utilData.fault_name}</span>
                <span> Thanks for your feedback!</span>
                <span>We're glad you find te answer correct</span>
                <br />
                <span>
                  {" "}
                  Here are some of the probable causes of this type of fault.{" "}
                </span>
                <ol style={{ "text-align": "left" }}>
                  {/* run map on responseData.probable_causes and return li */}
                  {utilData.probable_causes.filter(cause => cause !== "").map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))}

                </ol>

                <span></span>
                <span>
                  <Link
                    onClick={async () => {
                      await getExactCausesGiven({
                        uris: [drFilesS3Uri + requestInferData.fileName.replace(".dat", ".cfg")],
                        fault_name_arr: [utilData.fault_name]
                      });
                      // set util data
                      // debugger; 

                    }}
                  >
                    View the Exact causes of this fault
                  </Link>
                </span>
              </div>
            )}
            {currentStep === "NO_STEP_SHOW_EXACT_CAUSE_WAIT" && (
              <div className="dialog-section-1">
                <span>
                  Exact causes calculation in progress for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} for new fault {utilData.fault_name}
                </span>
                <span> Please wait while we calculate the new exact caauses</span>
                <Spinner style={{ margin: "auto" }} color="dark-grey" size="small" />
              </div>
            )
            }
            {currentStep === "NO_STEP_SHOW_EXACT_CAUSE" && (
              <div className="dialog-section-2">
                <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                <span className="dr-reason">{utilData.fault_name}</span>
                {/* TODO */}
                <span>Here are the exact causes of this type of fault.</span>
                <ol style={{ "text-align": "left" }}>
                  {exactCausesGiven.exact_causes.map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))
                  }
                </ol>

                <span> Does this exact causes look correct?</span>
                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Yes"
                    onClick={() => {
                      setcurrentStep("NO_STEP_SHOW_FINAL_CONFIRMATION");
                      setUtilData(
                        {
                          uri: drFilesS3Uri + requestInferData.fileName.replace(".dat", ".cfg"),
                          fault_name: utilData.fault_name,
                          is_new_fault: true,
                          probable_causes: utilData.probable_causes,
                          exact_causes: exactCausesGiven.exact_causes,
                          should_patch: true
                        }
                      );
                      // modify the button bg color to green

                    }}


                    shape={"pill"}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="No"
                    onClick={() => {
                      setcurrentStep("NO_STEP_GET_USER_INPUT_EXACT_CAUSE");
                    }}
                    shape={"pill"}
                  />
                </span>
              </div>
            )}

            {currentStep === "NO_STEP_SHOW_FINAL_CONFIRMATION" && (
              <div className="dialog-section-2">
                <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                <span className="dr-reason">{utilData.fault_name}</span>
                <span>Here are the exact causes of this type of fault.</span>
                <ol style={{ "text-align": "left" }}>
                  {exactCausesGiven.exact_causes.map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))
                  }
                </ol>
                <span>Great thank you for your awesome feedback</span>
              </div>
            )}

            {currentStep === "NO_STEP_GET_USER_INPUT_EXACT_CAUSE" && (
              <div className="dialog-section-2">
                <div className="dialog-section-2">
                  <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                  <span className="dr-reason">{utilData.fault_name}</span>
                  <span></span>
                  <span>
                    Sorry for my limited knowledge. Help me learn the exact
                    cause of this disturbance record.
                  </span>
                  <span>Exact Cause 1 </span>
                  <Input
                    type="normal"
                    style={{
                      flex: "1",
                      minWidth: "160px",
                      width: "260px",
                      maxWidth: "260px",
                    }}
                    placeholder="Enter exact cause 1 here"
                    value={exactCauses[0].exactcause1}
                    //        [{ ...drwidgetConfig[0], currentFile: filename }]
                    onValueChange={(val) =>
                      setexactCauses([{ ...exactCauses[0], exactcause1: val }])
                    }

                  ></Input>

                  <span>Exact Cause 2</span>
                  <Input
                    type="normal"
                    style={{
                      flex: "1",
                      minWidth: "160px",
                      width: "260px",
                      maxWidth: "260px",
                    }}
                    placeholder="Enter exact cause 2 here"
                    value={exactCauses[0].exactcause2}
                    //        [{ ...drwidgetConfig[0], currentFile: filename }]
                    onValueChange={(val) =>
                      setexactCauses([{ ...exactCauses[0], exactcause2: val }])
                    }
                  ></Input>
                  <span>
                    <Button
                      id="ack-button"
                      sizeClass={"small"}
                      type="ghost"
                      text="Cancel"
                      onClick={() => {
                        setcurrentStep("NO_STEP_SHOW_EXACT_CAUSE");

                      }}
                      shape={"pill"}
                    />
                    <Button
                      id="ack-button"
                      sizeClass={"small"}
                      type="ghost"
                      text="Next"
                      onClick={() => {
                        setcurrentStep("NO_STEP_SHOW_FINAL_TRAIN_CONFIRMATION");
                        setUtilData({
                          uri: drFilesS3Uri + requestInferData.fileName.replace(".dat", ".cfg"),
                          fault_name: utilData.fault_name,
                          is_new_fault: utilData.is_new_fault,
                          probable_causes: utilData.probable_causes,
                          exact_causes: [exactCauses[0].exactcause1, exactCauses[0].exactcause2],
                          should_patch: true
                        });
                      }}
                      shape={"pill"}
                    />
                  </span>
                </div>
              </div>
            )}
            {currentStep === "NO_STEP_SHOW_FINAL_TRAIN_CONFIRMATION" && (
              <div className="dialog-section-2">
                {/* <span>
                  {" "}
                  <b>CASE ML Analysis </b>
                </span> */}
                <span>Great thank you for your awesome feedback.</span>
                <span>
                  I have learned the exact causes shown below for this disturbance records.
                </span>
                <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                <span className="dr-reason">{utilData.fault_name}</span>

                <span>
                  <b>Exact causes</b>
                </span>
                <ol style={{ "text-align": "left" }}>
                  {utilData.exact_causes.map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))}
                </ol>
              </div>
            )}
            {currentStep === "WRONG_ML_FAULT" && (
              <div className="dialog-section-2">
                <span>
                  Sorry i have not been trained on this type of fault but from
                  my
                </span>
                <span>knowledge, i can tell the probable causes</span>
                <br />
                {getProbableCause()}
                <span>Please let us know the correct fault</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "260px",
                    maxWidth: "260px",
                    placeholder: "Enter value here",
                  }}
                  value={utilData.fault_name}
                  onValueChange={(val) =>
                    setUtilData({ ...utilData, fault_name: val, should_patch: false })
                  }
                ></Input>
                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Cancel"
                    onClick={() => {
                      setcurrentStep("NO_CASE_ANALYSIS");
                    }}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Next"
                    onClick={() => {
                      setcurrentStep("WRONG_ML_PROBABLE_CAUSE");
                    }}
                  />
                </span>
              </div>
            )}

            {currentStep === "WRONG_ML_PROBABLE_CAUSE" && (
              <div className="dialog-section-2">
                <span>
                  You can help me learn by entering the probable causes of this
                  new
                </span>
                <span>fault type one by one</span>
                <span>Probable Causes 1</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "260px",
                    maxWidth: "260px",
                    placeholder: "Enter value here",
                  }}
                  value={probableCauses[0].probablecause1}
                  onValueChange={(val) =>
                    setprobableCauses([{ ...probableCauses[0], probablecause1: val }])
                  }
                ></Input>
                <span>Probable Causes 2</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "260px",
                    maxWidth: "260px",
                    placeholder: "Enter value here",
                  }}
                  value={probableCauses[0].probablecause2}
                  onValueChange={(val) =>
                    setprobableCauses([{ ...probableCauses[0], probablecause2: val }])
                  }
                ></Input>
                <span>Probable Causes 3</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "260px",
                    maxWidth: "260px",
                    placeholder: "Enter value here",
                  }}
                  value={probableCauses[0].probablecause3}
                  onValueChange={(val) =>
                    setprobableCauses([{ ...probableCauses[0], probablecause3: val }])
                  }
                ></Input>
                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Cancel"
                    onClick={() => {
                      setcurrentStep("WRONG_ML_FAULT");
                    }}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Next"
                    onClick={() => {
                      setUtilData({
                        ...utilData,
                        probable_causes: [probableCauses[0].probablecause1, probableCauses[0].probablecause2, probableCauses[0].probablecause3],
                        should_patch: false
                      });
                      setcurrentStep("WRONG_ML_EXACT_CASUE");
                    }}
                  />
                </span>
              </div>
            )}

            {currentStep === "WRONG_ML_EXACT_CASUE" && (
              <div className="dialog-section-2">
                <span>
                  Now you can improve my knowledge by telling the exact
                </span>
                <span>
                  cause of this fault of the peobable cause you entered above.
                </span>
                <span>
                  Sorry for my limited knowledge. Help me learn exact cause of
                </span>
                <span>this disturbance record</span>

                <span>Exact Causes 1</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "260px",
                    maxWidth: "260px",
                    placeholder: "Enter value here",
                  }}
                  value={exactCauses[0].exactcause1}
                  onValueChange={(val) =>
                    setexactCauses([{ ...exactCauses[0], exactcause1: val }])
                  }
                ></Input>
                <span>Exact Causes 2</span>
                <Input
                  type="normal"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "260px",
                    maxWidth: "260px",
                    placeholder: "Enter value here",
                  }}
                  value={exactCauses[0].exactcause2}
                  onValueChange={(val) =>
                    setexactCauses([{ ...exactCauses[0], exactcause2: val }])
                  }
                ></Input>

                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Cancel"
                    onClick={() => {
                      setcurrentStep("WRONG_ML_PROBABLE_CAUSE");
                    }}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Next"
                    onClick={() => {
                      setUtilData({
                        ...utilData,
                        exact_causes: [exactCauses[0].exactcause1, exactCauses[0].exactcause2],
                        should_patch: true
                      });
                      setcurrentStep("WRONG_ML_FEEDBACK_LAST");
                    }}
                  />
                </span>
              </div>
            )}
            {currentStep === "WRONG_ML_FEEDBACK_LAST" && (
              <div className="dialog-section-2">
                <span>Great thank you for your awesome feedback.</span>
                <span>
                  I have learnt the exact causes shown below for this
                  disturbance
                </span>
                <span>records.</span>
                <br />

                <span> Fault identified for the DR file {requestInferData.fileName} in relay {requestInferData.relayName} is now considered as : </span>
                <span className="dr-reason">{utilData.fault_name}</span>
                <span> Thanks for your feedback!</span>
                <span>We're glad you find te answer correct</span>
                <br />
                <span>
                  {" "}
                  Probable causes{" "}
                </span>
                <ol style={{ "text-align": "left" }}>
                  {/* run map on responseData.probable_causes and return li */}
                  {utilData.probable_causes.map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))}

                </ol>
                <span>
                  {" "}
                  Exact causes{" "}
                </span>
                <ol style={{ "text-align": "left" }}>
                  {utilData.exact_causes.map((cause, index) => (
                    <li key={index}>{cause}</li>
                  ))}
                </ol>
              </div>
            )}

            {currentStep === "SHOW_PROBABLE_CAUSE" && (
              <div className="dialog-section-2">
                {getAnalysisComplete()}
                <span> Thanks for your feedback!</span>
                <span> We're glad you find the answer correct </span>
                {getProbableCause()}

                <span>
                  <Link
                    onClick={() => {
                      setcurrentStep("SHOW_EXACT_CAUSE");
                    }}
                  >
                    View the Exact causes of this fault
                  </Link>
                </span>
              </div>
            )}
            {currentStep === "SHOW_EXACT_CAUSE" && (
              <div className="dialog-section-2">
                {getAnalysisComplete()}
                <span> Here are the exact causes of this type of fault. </span>
                {getExactCause()}
                <span> Does this exact causes look correct?</span>
                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Yes"
                    onClick={() => {
                      setcurrentStep("SHOW_FINAL_CONFIRMATION");
                    }}
                    shape={"pill"}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="No"
                    onClick={() => {
                      setcurrentStep("GET_USER_INPUT_EXACT_CAUSE");

                    }}
                    shape={"pill"}
                  />
                </span>
              </div>
            )}
            {currentStep === "SHOW_FINAL_CONFIRMATION" && (
              <div className="dialog-section-2">
                {getAnalysisComplete()}
                <span> Here are the exact causes of this type of fault. </span>
                <ol style={{ "text-align": "left" }}>
                  <li> Fault wiring</li>
                  <li>Insulation failure</li>
                  <li>Lightning strikes</li>
                </ol>
                <span>Great thank you for your awesome feedback</span>
              </div>
            )}
            {currentStep === "GET_USER_INPUT_EXACT_CAUSE" && (
              <div className="dialog-section-2">
                {getAnalysisComplete()}
                <span>
                  Sorry for my limited knowledge. Help me learn the exact cause
                  of this disturbance record.
                </span>
                <span>Exact Cause 1 </span>
                <Input
                  type="normal"
                  dataType="text"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "260px",
                    maxWidth: "260px",
                    placeholder: "Enter exact cause 1 here",
                  }}
                  value={exactCauses[0].exactcause1}
                  //        [{ ...drwidgetConfig[0], currentFile: filename }]
                  onValueChange={(val) =>
                    setexactCauses([{ ...exactCauses[0], exactcause1: val }])
                  }
                ></Input>

                <span>Exact Cause 2</span>

                <Input
                  type="normal"
                  dataType="text"
                  style={{
                    flex: "1",
                    minWidth: "160px",
                    width: "260px",
                    maxWidth: "260px",
                    placeholder: "Enter exact cause 2 here",
                  }}
                  value={exactCauses[0].exactcause2}
                  //        [{ ...drwidgetConfig[0], currentFile: filename }]
                  onValueChange={(val) =>
                    setexactCauses([{ ...exactCauses[0], exactcause2: val }])
                  }
                ></Input>
                <span>
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Cancel"
                    onClick={() => {
                      setcurrentStep("SHOW_EXACT_CAUSE");
                    }}
                    shape={"pill"}
                  />
                  <Button
                    id="ack-button"
                    sizeClass={"small"}
                    type="ghost"
                    text="Next"
                    onClick={() => {
                      setcurrentStep("SHOW_FINAL_TRAIN_CONFIRMATION");
                      setUtilData({
                        uri: drFilesS3Uri + requestInferData.fileName.replace(".dat", ".cfg"),
                        fault_name: responseData.predicted_fault_value,
                        is_new_fault: false,
                        probable_causes: responseData.probable_causes,
                        exact_causes: [exactCauses[0].exactcause1, exactCauses[0].exactcause2],
                        should_patch: true

                      });
                    }}
                    shape={"pill"}
                  />
                </span>
              </div>
            )}
            {currentStep === "SHOW_FINAL_TRAIN_CONFIRMATION" && (
              <div className="dialog-section-2">
                {/* <span>
                  {" "}
                  <b>CASE ML Analysis </b>
                </span>
                <span>Great thank you for your awesome feedback.</span>
                <span>
                  I have learned the exact causes shown below for this
                  disturbance records.
                </span> */}
                {getAnalysisComplete()}

                <span>
                  <b>Exact causes</b>
                </span>
                <ol style={{ "text-align": "left" }}>
                  <li> {exactCauses[0].exactcause1}</li>
                  <li>{exactCauses[0].exactcause2}</li>
                </ol>
              </div>
            )}
            {/* {isAnalysisComplete && <div className="">

            <div>
            </div>
            } */}
            {/* {isAnalysisFinish && <div className="">Analysis Complete</div>} */}
            {/* {isAnalysisComplete && <div className="">Analysis Finsished</div>} */}
          </div>
        </Dialog>
      )}
      {isSpin ? (
        <Spinner style={{ margin: "auto" }} color="dark-grey" size="small" />
      ) : (
        <>
          {error ? (
            <span style={{ margin: "auto" }} color="dark-grey" size="small">
              {ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link
                style={{ color: "#1f1f1f" }}
                onClick={() => {
                  getApiData();
                }}
              >
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data.length > 0 ? (
                <Datagrid
                  className="table-style"
                  // style={{ height: "590px" }}
                  data={data}
                  columns={data.length > 0 ? columns : ""}
                  pageSize={pageSize}
                  page={currentPage}
                  rowHeight="small"
                  enableSorting={true}
                  sortOrder={sortOrder}
                  onSort={(so) => setSortOrder(so)}
                  columnOrder={columnOrder}
                />
              ) : (
                <p className="center-text-error">{ERROR_LIST.NO_RECORDS}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default DisturbanceMeasurementData;
