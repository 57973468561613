import Sites from "./components/Net-Zero-Components/Home/sites";
import Co2Emission from "./components/Net-Zero-Components/Home/co2-emission";
import AbbBuilding from "./components/Net-Zero-Components/Sites-KPIs/abb-building";
import SiteInformation from "./components/Net-Zero-Components/Sites-KPIs/site-information";
import CO2Saving from "./components/Net-Zero-Components/Sites-KPIs/co2-savings";
import ConsumptionByOccupancy from "./components/Net-Zero-Components/Sites-KPIs/consumption-by-occupancy";
import EnergyCost from "./components/Net-Zero-Components/Sites-KPIs/energy-cost";
import Weather from "./components/Net-Zero-Components/Sites-KPIs/weather";
import NzDropdownEnergyMix from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-energy-mix";
import NzDropdownCo2EmissionForecast from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-co2-emissions-forecast";
import Co2Emissions from "./components/Net-Zero-Components/Trends/co2-emissions";
import EnergyMix from "./components/Net-Zero-Components/Trends/energy-mix";
import Co2EmissionsForecast from "./components/Net-Zero-Components/Trends/co2-emissons-forecast";
import EnergyMixForecast from "./components/Net-Zero-Components/Trends/energy-mix-forecast";
import PowerFlow from "./components/Net-Zero-Components/Trends/power-flow";
import NzDropdownPowerFlow from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-power-flow";
import NzDropdownDeviceMeasures from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-device-measures";
import DeviceMeasures from "./components/Net-Zero-Components/Trends/device-measures";
import RenewableEnergy from "./components/Net-Zero-Components/Home/renewable-energy";
import ActualPower from "./components/Net-Zero-Components/Sites-KPIs/actual- power";
import CO2ETons from "./components/Net-Zero-Components/Sites-KPIs/co2e-tons";
import GreenEnergy from "./components/Net-Zero-Components/Sites-KPIs/green-energy";
import EVChargers from "./components/Net-Zero-Components/Sites-KPIs/ev-chargers";
import SankeyDemo from "./components/Net-Zero-Components/Sites-KPIs/demo";
import NzDropdownCo2Emission from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-co2-emissions";
import NzDropdownEnergyMixForecast from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-energy-mix-forecast";
export const BASE_URL = `https://${process.env.REACT_APP_TD_BASE_URL}.execute-api.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_LAMBDA_ENV}/`;

export const BASE_URL_CAES = `https://${process.env.REACT_APP_CAES_ML_BASE_URL}.execute-api.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_CAES_LAMBDA_ENV}/`;
export const S3_BUCKET_DR_CAES = `s3://${process.env.REACT_APP_CAES_DR_S3_BUCKET_NAME}/`;

//Row 3
export const BASE_URL_DISTURBANCE_RECORDS = `https://${process.env.REACT_APP_DR_BASE_URL}.execute-api.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_LAMBDA_ENV}/`;

export const BASE_URL_DISTURBANCE_GENERATE_URL = `https://${process.env.REACT_APP_DR_BASE_URL}.execute-api.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_LAMBDA_ENV}/cognien-abb-dr/url/generate?`;

export const BASE_URL_MFA = `https://${process.env.REACT_APP_AWS_COGNITO_MFA_URL}.auth.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazoncognito.com/mfa?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_AWS_COGNITO_MFA_ENV}.demo.cognien.abb%2F`;

export const ERROR_LIST = {
  ERROR_FETCH_DATA: `There was a problem fetching the data`,
  ALARM_SUMMARY_EMPTY_DATA: `There are no alarms to display at this time.`,
  NO_EVENTS: `There are no events available at this time.`,
  NO_RECORDS: `There are no records available at this time.`,
  NO_DATA: `There is no data available at this time.`,
  NO_NOTIFICATIONS: `There are no new notifications at this time.`,
};

export const MESSAGES = {
  SIGN_IN_TRIGGER: "Verifying Credentials, Please Wait...",
  SIGN_IN_TIME_OUT_TRIGGER: "",
  POST_SIGN_WAITING: "Waiting for User Mail Verification",
  POST_SIGN_UP_TRIGGER:
    "Thank you for creating an account with CogniEN.\n An email has been sent to your registered email. Please verify it to obtain approval from the administrators",
  IN_SIGNUP_TRIGGER: "Submitting Request...",
  CONFIRM_USER_TITLE: "Confirm User Details",
  CONFIRM_USER_BODY: "Are you sure you want to save user details?",
  CONFIRMED_USER_TITLE: "Updated User Details",
  CONFIRMED_USER_BODY:
    "User Details updated successfully. The updates will be reflected in the next login",
  SUBSTATION_AREAS_LOAD_MESSAGE:
    "Please wait while we fetch the substations from the assigned operation areas",
  SUBSTATION_LOADING: "Loading substations...",
};

//EM Widgets Section
//-------------------------API PARAMS--------------------------------
export const PLAT_ID = "77903d8a-ba6e-4510-b1bb-d96f415f2120";
export const WIDGET_ID = "bf4e2fac-b201-4c79-b9fc-ee81ad2b07ab";
export const EM_SUBSCRIPTION_KEY = "3b1f9088b056472085339605a9c133c1";
export const PLANT_TYPE = "EL";
export const IS_OPTIONS = true;
export const EM_BASE_URL = `https://api.electrification.ability.abb/common/api/plants/${PLAT_ID}/widget/${WIDGET_ID}`;
export const TOKEN =
  "eyJraWQiOiJiUnpMdEl3TXR5dzk5TStUKyswSmg3Y1NhK2dXeU9zdFFRODhUdyswaUFnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI3MTczZmRmYS1kMDgxLTcwNjUtMGYwZC1mY2VkYzVlN2Q3ODciLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX0FKbXRyZ0ZneSIsImNsaWVudF9pZCI6IjNpMjJwZDIyNDZnajVjMzBmNG9sOGo1anB1Iiwib3JpZ2luX2p0aSI6IjlmYTA2MWUxLTQ2MjktNDMzZS05ZWNiLTUxYTQwYmE2YWViZCIsImV2ZW50X2lkIjoiODQ0MzY0NGItOTg2OS00NzcyLWJlZTItYzgzMDZkNTMxMjg5IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTcxNTk0MDMyMSwiZXhwIjoxNzE2Mjc2MTIzLCJpYXQiOjE3MTYyNzI1MjMsImp0aSI6IjQzNjhmMGE0LTMwMjItNGYwNy1hNjdiLWZmNzdhNjQ1ODk0ZCIsInVzZXJuYW1lIjoiNzE3M2ZkZmEtZDA4MS03MDY1LTBmMGQtZmNlZGM1ZTdkNzg3In0.AhRmHgnKjJuwQZFV0SNy9K8tpnb8EHDOUrq-oD4WLJ3xWM5TS1ZT-vq1HP4xV6vBl3s5FdrJQArEKSxlQKFv3mEAbQ7q_-cMxr6Q7bbg-dBnOMUvuIShHa3RVMqMFAhnriRCT-K_KPw2qkM5vvrWvX3-jqowLMW6a_0LWDKf6wuIffYtBL6MRtaKZr6_YthACVESIV-BKuFLxP9Lv4rg94DGkNSjCA_Bhw6EilX3I06T3pvK6HHWlff8e8QweSl7vkiwJE4U9NVzT1e9j9ntbrKtrOAI1uF9OwLnajgSjdT9d8sGRXAJS8hONnM-cLVerLi9fH2Uu3Za46PN-eKtJg";
//"eyJhbGciOiJSUzI1NiIsImtpZCI6Ikt0aTJFX3JMTmdoMnNoQS1NbzF2dDBUcnNaLVB4MUgycFg4MGJQTU5pWGsiLCJ0eXAiOiJKV1QifQ.eyJlbWFpbCI6Im5hZ2VzaC50dXJ1cEBpbi5hYmIuY29tIiwibmFtZSI6Ik5hZ2VzaCBUdXJ1cCIsInRpZCI6IjA0YWMwZTc0LTUxNzEtNGQ5My1iM2VjLTk1ZGExYWJiZDI4MSIsIm9pZCI6ImFhYjBlOWM3LTdlMWQtNDE3ZS1hMzRjLTFhMjUwNzQwZjY5NCIsInN1YiI6ImFhYjBlOWM3LTdlMWQtNDE3ZS1hMzRjLTFhMjUwNzQwZjY5NCIsIm5vbmNlIjoiM2UxZGY1MGItY2JiNS00MTlmLWJhN2EtZjFhNWEyOWIyYmRjIiwic2NwIjoicmVnaW9uIiwiYXpwIjoiMGY2MDU0MzQtZWYyZC00NjU2LWFhMWQtMTRhMmI2M2RkZjZkIiwidmVyIjoiMS4wIiwiaWF0IjoxNzE2MjcyNTY5LCJhdWQiOiIxOWEzOWY0Mi1hMzA4LTRhOGYtYTEyMC04NzhkMjRmMDJhMGQiLCJleHAiOjE3MTYyNzYxNjksImlzcyI6Imh0dHBzOi8vYWJpbGl0eXJlbDEyZXVucHJkLmIyY2xvZ2luLmNvbS90ZnAvY2E3NmE2MTctODI0MS00ZDYzLTkwNTctODg4NTI1NmVjMTc0L2IyY18xYV9lbGNwLWlhbS1wcm9kLXRlbmFudF9ycC92Mi4wLyIsIm5iZiI6MTcxNjI3MjU2OX0.qtiIcAvfaLzo9wqc6QYtZNpdCh45YvybssWvFoSRnbTXbcUqsLPlw_DNOR9u3pEeWHLpKzdNKPDX3UeDW1BQEPfzD8RCk677MwNWCRG8Vw0AkyH5BwzE4QhebJMx8d1Ym9ZYHgUyYTxgCO3uEf-e9tHJAJqHfRIMY1oD7QgXQcaKOmlpXRL6Wkc2Hcbt_i6okFQhqwQE2LF719i2suWFOmfxFDEMQnKVVtuNjwZXUDpQ7KaFXhfcg4fvbUYZYktxK2qzKx8qE6jN_MrkHj-WWaPqPj2FUsyvkz4jbmWIxj-cQhiP-eE0shbjlWpLY57VIBYXoXn7BG4TUfj-8JjYnA"
//NZ Widgets Section

// export const NZ_BASE_URL = `https://d2s13vrj3reuh8.cloudfront.net/api/`;

export const NZ_BASE_URL =
  "https://kstm312sba.execute-api.us-east-1.amazonaws.com/api";

export const NZ_ERROR_LIST = {
  ERROR_FETCH_DATA: `There was a problem fetching the data`,
  ALARM_SUMMARY_EMPTY_DATA: `There are no alarms to display at this time.`,
  NO_EVENTS: `There are no events available at this time.`,
  NO_RECORDS: `There are no records available at this time.`,
  NO_DATA: `There is no data available at this time.`,
  NO_NOTIFICATIONS: `There are no new notifications at this time.`,
};

export const SITE_KPIS_CARD_JSON = [
  {
    widgetName: "ABB Building 1",
    widgetType: "small",
    nzcomponent: <AbbBuilding />,
  },
  {
    widgetName: "Actual Power (kW)",
    widgetType: "small",
    nzcomponent: <ActualPower />,
  },
  {
    widgetName: "ev Chargers (kW)",
    widgetType: "small",
    nzcomponent: <EVChargers />,
  },

  {
    widgetName: "Site Information",
    widgetType: "small",
    nzcomponent: <SiteInformation />,
  },
  {
    widgetName: "CO2e (tons)",
    widgetType: "small",
    nzcomponent: <CO2ETons />, //
  },
  {
    widgetName: "CO2e Savings (tons)",
    widgetType: "small",
    nzcomponent: <CO2Saving />, //
  },
  {
    widgetName: "Weather",
    widgetType: "small",
    nzcomponent: <Weather />,
  },

  {
    widgetName: "Energy Cost(€)",
    widgetType: "small",
    nzcomponent: <EnergyCost />,
  },

  {
    widgetName: "Consumption by Occupancy",
    widgetType: "small",
    nzcomponent: <ConsumptionByOccupancy />,
  },
  {
    widgetName: "Green Energy",
    widgetType: "small",
    nzcomponent: <GreenEnergy />,
  },
  {
    widgetName: "Energy Flow",
    widgetType: "medium",
    nzcomponent: <SankeyDemo />,
  },
];

export const HOME_CARD_JSON = [
  {
    widgetName: "Sites",
    widgetType: "extra-large",
    nzcomponent: <Sites />,
  },
  {
    widgetName: "CO2 Emissions",
    widgetType: "large",
    nzcomponent: <Co2Emission />,
  },
  {
    widgetName: "Renewable Energy",
    widgetType: "large",
    nzcomponent: <RenewableEnergy />,
  },
];

export const TRENDS_CARD_JSON = [
  {
    widgetName: "CO2 Emissions",
    widgetType: "trends",
    nzcomponent: <Co2Emissions />,
    dropdownComponent: <NzDropdownCo2Emission />,
  },
  {
    widgetName: "CO2 Emissions Forecast",
    widgetType: "trends",
    nzcomponent: <Co2EmissionsForecast />,
    dropdownComponent: <NzDropdownCo2EmissionForecast />,
  },
  {
    widgetName: "Energy Mix",
    widgetType: "trends",
    nzcomponent: <EnergyMix />,
    dropdownComponent: <NzDropdownEnergyMix />,
  },
  {
    widgetName: "Energy Mix Forecast",
    widgetType: "trends",
    nzcomponent: <EnergyMixForecast />,
    dropdownComponent: <NzDropdownEnergyMixForecast />,
  },
  {
    widgetName: "Power Flow",
    widgetType: "trends",
    nzcomponent: <PowerFlow />,
    dropdownComponent: <NzDropdownPowerFlow />,
  },
  {
    widgetName: "Device Measures",
    widgetType: "trends",
    nzcomponent: <DeviceMeasures />,
    dropdownComponent: <NzDropdownDeviceMeasures />,
  },
];
//EM Core Settings
export const EMCORE_TABS = ["Network", "Load Profiles", "Battery SoC", "Generation", "Grid"]
