import React from 'react';
import {
    NotificationContainer
} from "@abb/abb-common-ux-react";
export default function NotificationExample(props) {

    const { notitype } = props;

    const [notiData, setnotiData] = [{
        notifications: [{
            id: 1,
            type: 'banner',
            discreet: 1,
            severity: "info",
            timeout: 3000,
            text: `Please wait while we open the file in wavewin`,
        }]
    }];
    const [errornotiData, seterrornotiData] = [{
        errornotifications: [{
            id: 2,
            type: 'banner',
            discreet: 2,
            severity: "warn",
            timeout: 3000,
            text: `Error while generating url.`,
        }]
    }];
    const [updatepwd, setupdatepwd] = [{
        notifications: [{
            id: 1,

            type: 'banner',
            discreet: 1,
            severity: "success",
            timeout: 3000,
            text: `Password changed successfully`,
        }]
    }];
    return (
        <>
            {notitype === "true" && <NotificationContainer notifications={notiData.notifications} style={{ position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "error" &&
                <NotificationContainer notifications={errornotiData.errornotifications} style={{ position: 'absolute', top: '100px', right: '0px', zIndex: 9 }} />}
            {notitype === "updatePassword" && <NotificationContainer notifications={updatepwd.notifications} style={{ position: 'absolute', top: '50px', right: '0px', zIndex: 9 }} />}
        </>
    );

}
<NotificationExample />;