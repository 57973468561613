import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Datagrid, Icon, Dialog, Button } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../contexts/root-context";

import "./dashboard.css";
import UserSession from "../../../components/Authentication/user-session";

// import ProoferSubheader from '../../../components/proofer-tool-cmps/proofer-subheader';
import ProoferForm from '../../../components/proofer-tool-cmps/popup-form/proofer-form';
import ProoferTabs from '../../../components/proofer-tool-cmps/popup-output-tabs/popup-output-tabs';
import axios from 'axios';
import Navbar from '../../../components/Navbar/navbar';

function isBlank(str) {
    return str === null || str === undefined || (typeof str === 'string' && /^\s*$/.test(str));
}

function TestComp({ i, processStatus }) {
    React.useEffect(() => {
        return () => {
            // Cleanup if needed
        };
    }, []);

    return (
        <>
            {processStatus === "ALL_ATOMIC_QUERIES_PROCESSED" ? (
                <Icon name="abb/check-mark" style={{ marginRight: '16px' }} onClick={() => alert(`Status complete on row ${i}`)} />
            ) : (
                <Icon name="abb/hour-glass" style={{ marginRight: '16px' }} onClick={() => alert(`Clicked icon on row ${i}`)} />
            )}
        </>
    );
}

const MemoizedTestComp = React.memo(TestComp);

function ProoferDashboard() {

    const [showDialog, setShowDialog] = useState(false); // State to control dialog visibility
    const [programmaticDialogIsOpen, setProgrammaticDialogIsOpen] = React.useState(true);
    const [allDocsData, setAllDocsData] = useState([]);
    const [currentDocData, setCurrentDocData] = useState({});
    const { currentProoferState, setcurrentProoferState } = useContext(RootContext);
    // const postRequestUrlPrefix = "http://ec2-13-235-65-148.ap-south-1.compute.amazonaws.com:8002/";
    const postRequestUrlPrefix = "https://byebj6tdv7.execute-api.ap-south-1.amazonaws.com/dev/proofer/";
    const [loading, setLoading] = useState(false);

    function getProoferDashboardAllDocs() {

        // const headers = {
        //     "Content-Type": "application/json",
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Methods":
        //       "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        //     "Accept-Language": "en-US",
        //   }

        let requestURL = postRequestUrlPrefix + "doc/all";
        axios.get(requestURL,
            )
            .then((response) => {
                // console.log(response);1
                setAllDocsData(response.data["documents"]);
                // return response.data;
                setRecordsforDatagrid();

            })
            .catch(function (error) {
                console.log(error);
            }).finally(() => { });
    }

    useEffect(() => {
        // if(currentProoferState == "showtabs") {
        getProoferDashboardAllDocs();
        // }
    }, []);

    // TODO: add api call to get data
    useEffect(() => {
        //  if (currentProoferState == "dashboard") {
        getProoferDashboardAllDocs();
        //  }
    }, [currentProoferState, showDialog]);

    const setRecordsforDatagrid = async () => {

        const transformedData = allDocsData.map((doc, i) => ({
            rowId: doc.id,
            fields: {
                filename: doc.name,
                oppname: doc.metadata.oppno,
                projname: doc.metadata.project,
                custname: doc.metadata.customername,
                prodgroup: doc.metadata.productgroup,
                uploadedat: doc.metadata.createdon,
                state: (
                    <div>
                        <MemoizedTestComp key={`TestComp-${i}`} i={i} processStatus={doc.process_status} />
                    </div>
                ),
            },
            isSelected: i % 4 === 0 ? null : false,
            disableSelect: i % 5 === 0,
            rowStatus: i % 7,
            fieldStatuses: { Value: i % 7 },
        }));
        setData(transformedData);
        setRowCount(transformedData.length);
    }
    function createProoferForm() {
        setShowDialog(true);
        setcurrentProoferState("create-form");
    }
    const lorem = React.useMemo(
        () =>
            'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ' +
            'ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat duis aute irure ' +
            'dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat non ' +
            'proident sunt in culpa qui officia deserunt mollit anim id est laborum',
        []
    );

    const initialData = React.useMemo(
        () =>
            lorem
                .match(/(.*?\s){2}/g)
                .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
                .map((k, i) => ({
                    rowId: `row-key-${i}`,
                    fields: {
                        filename: `${i}`,
                        oppname: k,
                        projname: k,
                        custname: k,
                        prodgroup: `${k.length * 12} €`,
                        uploadedat: `${k.length * 12} €`,
                        state: (
                            <div>
                                <MemoizedTestComp key={`TestComp-${i}`} i={i} />
                            </div>
                        ),
                    },

                    isSelected: i % 4 === 0 ? null : false,
                    disableSelect: i % 5 === 0,
                    rowStatus: i % 7,
                    fieldStatuses: { Value: i % 7 },
                })),
        []
    );

    const initialColumns = React.useMemo(
        () => [
            { fieldKey: 'filename', title: 'File Name' },
            { fieldKey: 'oppname', title: 'Opportunity Name' },
            { fieldKey: 'projname', title: 'Project Name' },
            { fieldKey: 'custname', title: 'Customer Name' },
            { fieldKey: 'prodgroup', title: 'Product Group' },
            { fieldKey: 'uploadedat', title: 'Uploaded At' },

            {
                fieldKey: 'state',
                title: 'state',
                width: 64,
                sortFn: (a, b) => {
                    const valA = a.state.props.children.props.i;
                    const valB = b.state.props.children.props.i;
                    return valA < valB ? -1 : valA > valB ? 1 : 0;
                },
            },
        ],
        []
    );

    const [rowCount, setRowCount] = React.useState(initialData.length);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [data, setData] = React.useState(initialData);
    const [columns, setColumns] = React.useState(initialColumns);
    const [footerStyle, setFooterStyle] = React.useState('columns');
    const [columnedFooterData] = React.useState(
        initialColumns.reduce((acc, c) => {
            acc[c.fieldKey] = `Footer: ${c.title}`;
            return acc;
        }, {})
    );
    const [freeFooterData] = React.useState(
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <span>Some footer content here...</span>
            <span>...and more content here.</span>
        </div>
    );
    const [sortOrder, setSortOrder] = React.useState("desc");
    const [columnOrder, setColumnOrder] = React.useState(undefined);
    const [columnFilters, setColumnFilters] = React.useState({

        oppname: { value: '', placeholder: 'Opp name' },
        projname: { value: '', placeholder: 'Project Name' },
        custname: { value: '', placeholder: 'Customer Name' },
        prodgroup: { value: '', placeholder: 'Product group' },

    });
    const [activeRow, setActiveRow] = React.useState(undefined);
    const [hideColumnFilters, setHideColumnFilters] = React.useState(false);

    const handleColumnFilterChange = (newFilters) => {
        setColumnFilters(newFilters);
        const filteredData = initialData
            .map((c) => Object.assign({}, c))
            .filter((c) => {
                return (
                    // (isBlank(newFilters.filename.value) || ('' + c.fields.Id).toLowerCase().indexOf(newFilters.filename.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.oppname.value) || ('' + c.fields.oppname).toLowerCase().indexOf(newFilters.oppname.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.projname.value) || ('' + c.fields.projname).toLowerCase().indexOf(newFilters.projname.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.custname.value) || ('' + c.fields.custname).toLowerCase().indexOf(newFilters.custname.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.prodgroup.value) || ('' + c.fields.prodgroup).toLowerCase().indexOf(newFilters.prodgroup.value.toLowerCase()) > -1)

                );
            });
        setData(filteredData);
        setRowCount(filteredData.length);
        setCurrentPage(0);
    };

    const handleSelectRow = (rowIndex) => {
        setData(
            data.map((d, i) => Object.assign(d, { isSelected: rowIndex === i ? !d.isSelected : d.isSelected }))
        );
    };




    const handleRowActivation = (rowIndex, rowId) => {
        setData(data.map((d, i) => Object.assign(d, { isActive: d.rowId === rowId })));
        setActiveRow(data.filter((r) => r.rowId === rowId)[0]);
    };




    return (
        <>
            <UserSession />
            <Navbar />
            {currentProoferState == "showtabs" ? <ProoferTabs /> :
                <>
                    <div className="proofer-header-container">
                        <div className="col-5 col-m-12 col-s-12">
                            {/* <div className="filter-header">DR Dashboard</div> */}
                        </div>
                        <div className="col-7 col-m-12 col-s-12">
                            {/* {startTS} To {endTS} */}
                            <div className="filters-group row">
                                <Button
                                    text="Generate"
                                    onClick={() => createProoferForm()}
                                    shape="pill"
                                    type="primary-white"
                                />
                                <Button
                                    text="Refresh"
                                    onClick={() => { getProoferDashboardAllDocs() }}
                                    shape="pill"
                                    type="primary-white"
                                />
                            </div>
                        </div>
                    </div>
                    {showDialog && currentProoferState == "create-form" ? <ProoferForm showDialog={showDialog} setShowDialog={setShowDialog} /> : ""}
                    {showDialog && currentProoferState == "showtabs" ? <ProoferTabs showDialog={showDialog} setShowDialog={setShowDialog} /> : ""}

                    <Datagrid
                        id='proofer-datagrid'
                        className="ABB_CommonUX_Datagrid__dark"
                        columns={columns}
                        columnFilters={columnFilters}
                        hideColumnFilters={hideColumnFilters}
                        footerStyle={footerStyle}
                        columnedFooterData={columnedFooterData}
                        freeFooterData={freeFooterData}
                        data={data}
                        rowCount={rowCount}
                        onSelectRow={handleSelectRow}
                        sortOrder={sortOrder}
                        enableSorting={true}
                        columnOrder={columnOrder}
                        setSortOrder={setSortOrder}
                        setColumnOrder={setColumnOrder}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={setCurrentPage}
                        onPageSizeChange={setPageSize}
                        hasSelectAll={true}
                        onColumnFiltersChange={handleColumnFilterChange}
                        onClickColumnFiltersIcon={() => setHideColumnFilters(!hideColumnFilters)}
                        onClickClearColumnFilters={() => {
                            const newFilters = Object.assign({}, columnFilters);
                            Object.keys(newFilters).forEach((k) => {
                                newFilters[k].value = '';
                            });
                            handleColumnFilterChange(newFilters);
                            setHideColumnFilters(true);
                        }}
                        customMessage={data.length !== 0 ? undefined : 'Nothing in data, please click refresh button'}

                    /> </>}

        </>

    );
}

export default ProoferDashboard;